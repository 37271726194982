import React, { useState, useEffect } from 'react';
import { Carousel } from "react-responsive-carousel";

// Importaciones

import { useHistory, useLocation} from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";


const Contact_pipedrive = () => {

  const history = useHistory();
  const location = useLocation();
  const prefix = useLocalePrefix();

  const queryParams = new URLSearchParams(location.search);
  const personId = queryParams.get("person_id");
  const organizationId = queryParams.get("org_id");
  const leadId = queryParams.get("lead_id");


  const [formData, setFormData] = useState({
    lookingFor: '', // Estoy buscando
    industryType: '', // Tipo de Industria
    businessSpecialty: '', // Especialidad del negocio
    collaboratorsCount: '', // Cantidad de colaboradores
    interestedIn: '', // Estoy interesado en
    occupation: '', // Cuál es su ocupación
    preferredContactMethod: '', // Método preferido de contacto
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [countryCode, setCountryCode] = useState(''); // Nuevo estado para el código de país
  const [step, setStep] = useState(1); // Paso actual del formulario
  const [errors, setErrors] = useState({});


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateStep = () => {
    const stepErrors = {};

    if (step === 1) { // Antes era step 2
      if (!formData.lookingFor.trim()) stepErrors.lookingFor = "Debe seleccionar una opción";
    } else if (step === 2) { // Antes era step 3
      if (!formData.industryType.trim()) stepErrors.industryType = "Debe seleccionar un tipo de industria";
    } else if (step === 3) { // Antes era step 4
      if (!formData.businessSpecialty.trim()) stepErrors.businessSpecialty = "Debe seleccionar una especialidad de negocio";
    } else if (step === 4) { // Antes era step 5
      if (!formData.interestedIn.trim()) stepErrors.interestedIn = "Debe seleccionar al menos una opción";
    } else if (step === 5) { // Antes era step 6
      if (!formData.collaboratorsCount.trim()) stepErrors.collaboratorsCount = "Debe seleccionar una cantidad de colaboradores";
    } else if (step === 6) { // Antes era step 7
      if (!formData.occupation.trim()) stepErrors.occupation = "Debe seleccionar una ocupación";
    } else if (step === 7) { // Antes era step 8
      if (!formData.preferredContactMethod.trim()) stepErrors.preferredContactMethod = "Debe seleccionar un método de contacto";
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };


  const handleSubmit = async () => {
    if (validateStep()) {
      const apiKey = "8bb3ead1828b0ca5512f504dbed1d0758bf5b583"; 
      const baseUrl = "https://api.pipedrive.com/v1";
  
      try {
        console.log("📌 Datos a enviar:", formData);
        console.log("📌 IDs recibidos:", { personId, organizationId, leadId });
  
        // Validar que todos los IDs existen
        if (!leadId || !organizationId || !personId) {
          console.error("❌ ERROR: Faltan identificadores en la URL");
          setResponseMessage("Error: No se encontraron IDs válidos.");
          return;
        }
  
        // ✅ 1️⃣ Actualizar la Organización
        const orgResponse = await fetch(`${baseUrl}/organizations/${organizationId}?api_token=${apiKey}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            'bf3f6fea0c15abffd343db2117f413971d7b726e': formData.industryType,
            'c50172cbedee0d2a8ccb081a96e23c8e6942d634': formData.businessSpecialty,
            'ac783445a175f07334ce0cc1bd61695e7440521d': formData.collaboratorsCount,
          }),
        });
  
        const orgData = await orgResponse.json();
        console.log("✅ Respuesta de Organización:", orgData);
  
        // ✅ 2️⃣ Actualizar el Contacto
        const personResponse = await fetch(`${baseUrl}/persons/${personId}?api_token=${apiKey}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            '4813411585b6caa0f48920a1cf0da8d76e554ce8': formData.preferredContactMethod,
          }),
        });
  
        const personData = await personResponse.json();
        console.log("✅ Respuesta de Persona:", personData);
  
        // ✅ 3️⃣ Verificar si el Lead existe antes de actualizarlo
        console.log("🔍 Verificando existencia del Lead...");
        const checkLeadResponse = await fetch(`${baseUrl}/leads/${leadId}?api_token=${apiKey}`);
        
        if (!checkLeadResponse.ok) {
          throw new Error("❌ El Lead no existe o no se puede actualizar.");
        }
  
        // ✅ 4️⃣ Actualizar el Lead con `PATCH` en lugar de `PUT`
        const leadBody = JSON.stringify({
          'a3ec9e0923bd0bd2f017bda85b8baea4c34a1c67': formData.lookingFor,
          'bb6652a6212ba1b6f0bc525310e35aa9f346e203': formData.interestedIn,
          '6e9e5a3623cd707caa339229d184b08ba4f0c735': formData.occupation,
        });
  
        console.log("📌 Enviando actualización del Lead con:", leadBody);
  
        const leadResponse = await fetch(`${baseUrl}/leads/${leadId}?api_token=${apiKey}`, {
          method: "PATCH", // 🔥 CAMBIO IMPORTANTE: `PATCH` en lugar de `PUT`
          headers: { "Content-Type": "application/json" },
          body: leadBody,
        });
  
        const leadData = await leadResponse.json();
        console.log("✅ Respuesta de Lead:", leadData);
  
        if (!leadResponse.ok) {
          throw new Error(`Error en Lead: ${JSON.stringify(leadData)}`);
        }
  
        setResponseMessage("Formulario enviado con éxito");
        history.push("/test-thanks-for-sending-message-pipedrive");
  
      } catch (error) {
        setResponseMessage(`❌ Error: ${error.message}`);
        console.error("❌ Error en la actualización:", error);
      }
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
          <div>
            <label className="block text-2xl font-medium text-gray-700 mb-8">
              Estoy buscando...
            </label>
            <div className="mt-1">
                <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                <div>
                  <input
                    type="radio"
                    name="lookingFor"
                    value="Abrir mi primer negocio"
                    id="lookingForOption1"
                    className="peer hidden"
                    checked={formData.lookingFor === "Abrir mi primer negocio"}
                    onChange={() => {
                      setFormData({ ...formData, lookingFor: "Abrir mi primer negocio" });
                    }}
                  />
                  <label
                    htmlFor="lookingForOption1"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/step%20one%20icons/Describe%201%20-%20Icon%281%29.svg"
                          className="w-14"
                          alt="Abrir mi primer negocio"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                      Abrir mi primer negocio
                    </p>
                  </label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="lookingFor"
                    value="Abrir una nueva sucursal"
                    id="lookingForOption2"
                    className="peer hidden"
                    checked={formData.lookingFor === "Abrir una nueva sucursal"}
                    onChange={() => {
                      setFormData({ ...formData, lookingFor: "Abrir una nueva sucursal" });
                    }}
                  />
                  <label
                    htmlFor="lookingForOption2"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/step%20one%20icons/Describe%203%20-%20Icon.svg"
                          className="w-14"
                          alt="Abrir una nueva sucursal"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                      Abrir una nueva sucursal
                    </p>
                  </label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="lookingFor"
                    value="Crecer mi negocio"
                    id="lookingForOption3"
                    className="peer hidden"
                    checked={formData.lookingFor === "Crecer mi negocio"}
                    onChange={() => {
                      setFormData({ ...formData, lookingFor: "Crecer mi negocio" });
                    }}
                  />
                  <label
                    htmlFor="lookingForOption3"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/growth%20icon.svg"
                          className="w-14"
                          alt="Crecer mi negocio"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                      Crecer mi negocio
                    </p>
                  </label>
                </div>
              </div>
                  
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handleBack}
                  className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                >
                  Atrás
                </button>
                <button
                  type="button"
                  onClick={handleNext}
                  className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                >
                  Siguiente
                </button>
              </div>
            </div>
          </div>
          </>
        );
        case 2:
          return (
            <>
             <div>
                <label className="block text-2xl font-medium text-gray-700 mb-8">
                  Tipo de Industria
                </label>
                <div className="mt-1">
                  <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                    <div>
                      <input
                        type="radio"
                        name="industryType"
                        value="Alimentos y Bebidas"
                        id="industryTypeOption1"
                        className="peer hidden"
                        checked={formData.industryType === "Alimentos y Bebidas"}
                        onChange={() => {
                          setFormData({ ...formData, industryType: "Alimentos y Bebidas" });
                        }}
                      />
                      <label
                        htmlFor="industryTypeOption1"
                        className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                      >
                        <div className="flex justify-center">
                          <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                            <img
                              src="https://img.invupos.com/webinvu/confirmed%20demo/Alimentos%20y%20bebidas%20Icon.svg"
                              className="w-14"
                              alt="Alimentos y Bebidas"
                            />
                          </div>
                        </div>
                        <p className="text-gray-800 text-base font-normal text-center">
                          Alimentos y Bebidas
                        </p>
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        name="industryType"
                        value="Minoristas"
                        id="industryTypeOption2"
                        className="peer hidden"
                        checked={formData.industryType === "Minoristas"}
                        onChange={() => {
                          setFormData({ ...formData, industryType: "Minoristas" });
                        }}
                      />
                      <label
                        htmlFor="industryTypeOption2"
                        className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                      >
                        <div className="flex justify-center">
                          <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                            <img
                              src="https://img.invupos.com/webinvu/confirmed%20demo/Minoristas%20icon.svg"
                              className="w-14"
                              alt="Minoristas"
                            />
                          </div>
                        </div>
                        <p className="text-gray-800 text-base font-normal text-center">
                          Minoristas
                        </p>
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        name="industryType"
                        value="Entretenimiento"
                        id="industryTypeOption3"
                        className="peer hidden"
                        checked={formData.industryType === "Entretenimiento"}
                        onChange={() => {
                          setFormData({ ...formData, industryType: "Entretenimiento" });
                        }}
                      />
                      <label
                        htmlFor="industryTypeOption3"
                        className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                      >
                        <div className="flex justify-center">
                          <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                            <img
                              src="https://img.invupos.com/webinvu/confirmed%20demo/Entretenimiento.svg"
                              className="w-14"
                              alt="Entretenimiento"
                            />
                          </div>
                        </div>
                        <p className="text-gray-800 text-base font-normal text-center">
                          Entretenimiento
                        </p>
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        name="industryType"
                        value="Hospitalidad y Turismo"
                        id="industryTypeOption4"
                        className="peer hidden"
                        checked={formData.industryType === "Hospitalidad y Turismo"}
                        onChange={() => {
                          setFormData({ ...formData, industryType: "Hospitalidad y Turismo" });
                        }}
                      />
                      <label
                        htmlFor="industryTypeOption4"
                        className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                      >
                        <div className="flex justify-center">
                          <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                            <img
                              src="https://img.invupos.com/webinvu/confirmed%20demo/Hospitalidad.svg"
                              className="w-14"
                              alt="Hospitalidad y Turismo"
                            />
                          </div>
                        </div>
                        <p className="text-gray-800 text-base font-normal text-center">
                          Hospitalidad y Turismo
                        </p>
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        name="industryType"
                        value="Servicios Profesionales"
                        id="industryTypeOption5"
                        className="peer hidden"
                        checked={formData.industryType === "Servicios Profesionales"}
                        onChange={() => {
                          setFormData({ ...formData, industryType: "Servicios Profesionales" });
                        }}
                      />
                      <label
                        htmlFor="industryTypeOption5"
                        className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                      >
                        <div className="flex justify-center">
                          <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                            <img
                              src="https://img.invupos.com/webinvu/confirmed%20demo/Servicios%20profesionales.svg"
                              className="w-14"
                              alt="Servicios Profesionales"
                            />
                          </div>
                        </div>
                        <p className="text-gray-800 text-base font-normal text-center">
                          Servicios Profesionales
                        </p>
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        name="industryType"
                        value="Otros"
                        id="industryTypeOption6"
                        className="peer hidden"
                        checked={formData.industryType === "Otros"}
                        onChange={() => {
                          setFormData({ ...formData, industryType: "Otros" });
                        }}
                      />
                      <label
                        htmlFor="industryTypeOption6"
                        className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                      >
                        <div className="flex justify-center">
                          <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                            <img
                              src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg"
                              className="w-14"
                              alt="Otros"
                            />
                          </div>
                        </div>
                        <p className="text-gray-800 text-base font-normal text-center">
                          Otros
                        </p>
                      </label>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <button
                      type="button"
                      onClick={handleBack}
                      className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                    >
                      Atrás
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        if (formData.industryType === "Otros") {
                          // Si la industria es "Otros", establece un valor predeterminado y avanza al paso 5
                          setFormData({ ...formData, businessSpecialty: "No especificado" });
                          if (validateStep()) { // Ejecuta las validaciones de ese paso antes de avanzar
                            setStep(4);
                          }
                        } else {
                          // Si no es "Otros", simplemente avanza al paso 4
                          if (validateStep()) { // Ejecuta las validaciones de ese paso antes de avanzar
                            setStep(3);
                          }
                        }
                      }}
                      className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
      case 3:
        return (
          <>
          {/* Especialidad de Negocio - TIPO DE RESTAURANTE */}
          {formData.industryType === "Alimentos y Bebidas" && (
            <div style={{ marginBottom: "15px" }}>
              <label className="block text-2xl font-medium text-gray-700 mb-8">
                Especialidad del Negocio - Tipo de Restaurante:
              </label>
              <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyOption1"
                    name="businessSpecialty"
                    value="Alta cocina"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Alta cocina"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyOption1"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Alta%20cocina%281%29.svg"
                          className="w-14"
                          alt="Alta cocina"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Alta cocina
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyOption2"
                    name="businessSpecialty"
                    value="Servicio completo informal"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Servicio completo informal"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyOption2"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Servicio%20Completo%20Informal.svg"
                          className="w-14"
                          alt="Servicio completo informal"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Servicio completo informal
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyOption3"
                    name="businessSpecialty"
                    value="Servicio rápido informal"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Servicio rápido informal"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyOption3"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Servicio%20rapido%20informal.svg"
                          className="w-14"
                          alt="Servicio rápido informal"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Servicio rápido informal
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyOption4"
                    name="businessSpecialty"
                    value="Un camión de comida"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Un camión de comida"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyOption4"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Food%20truckk.svg"
                          className="w-14"
                          alt="Un camión de comida"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Un camión de comida
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyOption5"
                    name="businessSpecialty"
                    value="Una cafetería o panadería"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Una cafetería o panadería"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyOption5"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Bakeryy.svg"
                          className="w-14"
                          alt="Una cafetería o panadería"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Una cafetería o panadería
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyOption6"
                    name="businessSpecialty"
                    value="Un bar o discoteca"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Un bar o discoteca"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyOption6"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Bar%20pubs.svg"
                          className="w-14"
                          alt="Un bar o discoteca"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Un bar o discoteca
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyOption7"
                    name="businessSpecialty"
                    value="Algo más"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Algo más"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyOption7"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg"
                          className="w-14"
                          alt="Algo más"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Algo más
                    </p>
                  </label>
                </div>
              </div>
            </div>
          )}

          {/* Especialidad de Negocio - TIPO DE MINORISTA */}
          {formData.industryType === "Minoristas" && (
            <div style={{ marginBottom: "15px" }}>
              <label className="block text-2xl font-medium text-gray-700 mb-8">
                Especialidad del Negocio - Tipo de Minorista:
              </label>
              <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyRetail1"
                    name="businessSpecialty"
                    value="Tiendas de Conveniencia"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Tiendas de Conveniencia"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyRetail1"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Tienda%20de%20conveniencia.svg"
                          className="w-14"
                          alt="Tiendas de Conveniencia"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Tiendas de Conveniencia
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyRetail2"
                    name="businessSpecialty"
                    value="Grandes Almacenes"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Grandes Almacenes"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyRetail2"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Grandes%20almacenes.svg"
                          className="w-14"
                          alt="Grandes Almacenes"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Grandes Almacenes
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyRetail3"
                    name="businessSpecialty"
                    value="Tiendas de Descuento"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Tiendas de Descuento"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyRetail3"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Tiendas%20de%20descuento.svg"
                          className="w-14"
                          alt="Tiendas de Descuento"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Tiendas de Descuento
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyRetail4"
                    name="businessSpecialty"
                    value="Supermercados y Tiendas de Comestibles"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Supermercados y Tiendas de Comestibles"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyRetail4"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Supermercados.svg"
                          className="w-14"
                          alt="Supermercados y Tiendas de Comestibles"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Supermercados y Tiendas de Comestibles
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyRetail5"
                    name="businessSpecialty"
                    value="Tiendas Especializadas o de Nicho"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Tiendas Especializadas o de Nicho"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyRetail5"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Tiendas%20de%20nicho.svg"
                          className="w-14"
                          alt="Tiendas Especializadas o de Nicho"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Tiendas Especializadas o de Nicho
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="businessSpecialtyRetail6"
                    name="businessSpecialty"
                    value="Tiendas en Línea o E-commerce"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Tiendas en Línea o E-commerce"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="businessSpecialtyRetail6"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Ecommerce.svg"
                          className="w-14"
                          alt="Tiendas en Línea o E-commerce"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Tiendas en Línea o E-commerce
                    </p>
                  </label>
                </div>
              </div>
            </div>
          )}
    
          {/* Especialidad de Negocio - TIPO DE ENTRETENIMIENTO */}
          {formData.industryType === "Entretenimiento" && (
            <div style={{ marginBottom: "15px" }}>
              <label className="block text-2xl font-medium text-gray-700 mb-8">
                Especialidad del Negocio - Tipo de Entretenimiento:
              </label>
              <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                <div>
                  <input
                    type="radio"
                    id="entertainmentOption1"
                    name="businessSpecialty"
                    value="Cine"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Cine"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="entertainmentOption1"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Cine.svg"
                          className="w-14"
                          alt="Cine"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Cine
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="entertainmentOption2"
                    name="businessSpecialty"
                    value="Parques de atracciones"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Parques de atracciones"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="entertainmentOption2"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Parque%20de%20atracciones.svg"
                          className="w-14"
                          alt="Parques de atracciones"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Parques de atracciones
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="entertainmentOption3"
                    name="businessSpecialty"
                    value="Clubes nocturnos"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Clubes nocturnos"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="entertainmentOption3"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Clubes%20nocturnos.svg"
                          className="w-14"
                          alt="Clubes nocturnos"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Clubes nocturnos
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="entertainmentOption4"
                    name="businessSpecialty"
                    value="Teatros"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Teatros"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="entertainmentOption4"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Teatros.svg"
                          className="w-14"
                          alt="Teatros"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Teatros
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="entertainmentOption5"
                    name="businessSpecialty"
                    value="Casinos"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Casinos"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="entertainmentOption5"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Casinos.svg"
                          className="w-14"
                          alt="Casinos"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Casinos
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="entertainmentOption6"
                    name="businessSpecialty"
                    value="Canchas Deportivas"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Canchas Deportivas"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="entertainmentOption6"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Canchas.svg"
                          className="w-14"
                          alt="Canchas Deportivas"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Canchas Deportivas
                    </p>
                  </label>
                </div>
              </div>
            </div>
          )}

          {/* Especialidad de Negocio - TIPO DE HOSPITALIDAD Y TURISMO */}
          {formData.industryType === "Hospitalidad y Turismo" && (
            <div style={{ marginBottom: "15px" }}>
              <label className="block text-2xl font-medium text-gray-700 mb-8">
                Especialidad del Negocio - Tipo de Hospitalidad y Turismo:
              </label>
              <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                <div>
                  <input
                    type="radio"
                    id="hospitalityOption1"
                    name="businessSpecialty"
                    value="Hoteles y Moteles"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Hoteles y Moteles"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="hospitalityOption1"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Hoteles%20y%20moteles.svg"
                          className="w-14"
                          alt="Hoteles y Moteles"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Hoteles y Moteles
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="hospitalityOption2"
                    name="businessSpecialty"
                    value="Restaurantes y Cafeterías"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Restaurantes y Cafeterías"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="hospitalityOption2"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Restaurantes%20y%20cafeterias.svg"
                          className="w-14"
                          alt="Restaurantes y Cafeterías"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Restaurantes y Cafeterías
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="hospitalityOption3"
                    name="businessSpecialty"
                    value="Agencias de Viajes"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Agencias de Viajes"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="hospitalityOption3"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Agencias%20de%20viajes.svg"
                          className="w-14"
                          alt="Agencias de Viajes"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Agencias de Viajes
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="hospitalityOption4"
                    name="businessSpecialty"
                    value="Operadores de Tours"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Operadores de Tours"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="hospitalityOption4"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Operadores%20de%20tours.svg"
                          className="w-14"
                          alt="Operadores de Tours"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Operadores de Tours
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="hospitalityOption5"
                    name="businessSpecialty"
                    value="Líneas de Cruceros"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Líneas de Cruceros"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="hospitalityOption5"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Lineas%20de%20crucero.svg"
                          className="w-14"
                          alt="Líneas de Cruceros"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Líneas de Cruceros
                    </p>
                  </label>
                </div>
              </div>
            </div>
          )}
          
          {/* Especialidad de Negocio - TIPO DE SERVICIOS PROFESIONALES */}
          {formData.industryType === "Servicios Profesionales" && (
            <div style={{ marginBottom: "15px" }}>
              <label className="block text-2xl font-medium text-gray-700 mb-8">
                Tipo de Servicios Profesionales:
              </label>
              <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                <div>
                  <input
                    type="radio"
                    id="professionalServicesOption1"
                    name="businessSpecialty"
                    value="Salones de Belleza y Barberías"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Salones de Belleza y Barberías"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="professionalServicesOption1"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Salones%20de%20belleza.svg"
                          className="w-14"
                          alt="Salones de Belleza y Barberías"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Salones de Belleza y Barberías
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="professionalServicesOption2"
                    name="businessSpecialty"
                    value="Spas"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Spas"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="professionalServicesOption2"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Spas.svg"
                          className="w-14"
                          alt="Spas"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Spas
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="professionalServicesOption3"
                    name="businessSpecialty"
                    value="Agencias de Consultoría"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Agencias de Consultoría"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="professionalServicesOption3"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Agencia%20de%20consultoria.svg"
                          className="w-14"
                          alt="Agencias de Consultoría"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Agencias de Consultoría
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="professionalServicesOption4"
                    name="businessSpecialty"
                    value="Servicios Médicos Especializados"
                    className="peer hidden"
                    checked={formData.businessSpecialty === "Servicios Médicos Especializados"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="professionalServicesOption4"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Servicio%20medico.svg"
                          className="w-14"
                          alt="Servicios Médicos Especializados"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Servicios Médicos Especializados
                    </p>
                  </label>
                </div>
              </div>
            </div>
          )}
    
          <div className="flex justify-between">
            <button
              type="button"
              onClick={handleBack}
              className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            >
              Atrás
            </button>
            <button
              type="button"
              onClick={handleNext}
              className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            >
              Siguiente
            </button>
          </div>
        </>
        );
        case 4:
          return (
            <>
             <div style={{ marginBottom: "15px" }}>
                <label className="block text-2xl font-medium text-gray-700 mb-8">
                  Estoy interesado en:
                </label>
                <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                  <div>
                    <input
                      type="checkbox"
                      id="interestedOption1"
                      name="interestedIn"
                      value="Recibir Pagos"
                      className="peer hidden"
                      checked={formData.interestedIn.includes("Recibir Pagos")}
                      onChange={(e) => {
                        const value = e.target.value;
                        const selectedValues = formData.interestedIn.split(", ").filter(Boolean);
                        if (e.target.checked) {
                          setFormData({ ...formData, interestedIn: [...selectedValues, value].join(", ") });
                        } else {
                          setFormData({ ...formData, interestedIn: selectedValues.filter((v) => v !== value).join(", ") });
                        }
                      }}
                    />
                    <label
                      htmlFor="interestedOption1"
                      className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                    >
                      <div className="flex justify-center">
                        <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                          <img
                            src="https://img.invupos.com/webinvu/confirmed%20demo/Recibir%20pagos.svg"
                            className="w-14"
                            alt="Recibir Pagos"
                          />
                        </div>
                      </div>
                      <p className="text-gray-800 text-base font-normal text-center">
                        Recibir Pagos
                      </p>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="interestedOption2"
                      name="interestedIn"
                      value="Kioscos de Autoservicio"
                      className="peer hidden"
                      checked={formData.interestedIn.includes("Kioscos de Autoservicio")}
                      onChange={(e) => {
                        const value = e.target.value;
                        const selectedValues = formData.interestedIn.split(", ").filter(Boolean);
                        if (e.target.checked) {
                          setFormData({ ...formData, interestedIn: [...selectedValues, value].join(", ") });
                        } else {
                          setFormData({ ...formData, interestedIn: selectedValues.filter((v) => v !== value).join(", ") });
                        }
                      }}
                    />
                    <label
                      htmlFor="interestedOption2"
                      className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                    >
                      <div className="flex justify-center">
                        <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                          <img
                            src="https://img.invupos.com/webinvu/confirmed%20demo/Kiosko%20de%20auto%20servicio.svg"
                            className="w-14"
                            alt="Kioscos de Autoservicio"
                          />
                        </div>
                      </div>
                      <p className="text-gray-800 text-base font-normal text-center">
                        Kioscos de Autoservicio
                      </p>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="interestedOption3"
                      name="interestedIn"
                      value="Pedidos en línea y entrega a domicilio"
                      className="peer hidden"
                      checked={formData.interestedIn.includes("Pedidos en línea y entrega a domicilio")}
                      onChange={(e) => {
                        const value = e.target.value;
                        const selectedValues = formData.interestedIn.split(", ").filter(Boolean);
                        if (e.target.checked) {
                          setFormData({ ...formData, interestedIn: [...selectedValues, value].join(", ") });
                        } else {
                          setFormData({ ...formData, interestedIn: selectedValues.filter((v) => v !== value).join(", ") });
                        }
                      }}
                    />
                    <label
                      htmlFor="interestedOption3"
                      className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                    >
                      <div className="flex justify-center">
                        <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                          <img
                            src="https://img.invupos.com/webinvu/confirmed%20demo/Auto%20servicio.svg"
                            className="w-14"
                            alt="Pedidos en línea y entrega a domicilio"
                          />
                        </div>
                      </div>
                      <p className="text-gray-800 text-base font-normal text-center">
                        Pedidos en línea y entrega a domicilio
                      </p>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="interestedOption4"
                      name="interestedIn"
                      value="Marketing por correo electrónico"
                      className="peer hidden"
                      checked={formData.interestedIn.includes("Marketing por correo electrónico")}
                      onChange={(e) => {
                        const value = e.target.value;
                        const selectedValues = formData.interestedIn.split(", ").filter(Boolean);
                        if (e.target.checked) {
                          setFormData({ ...formData, interestedIn: [...selectedValues, value].join(", ") });
                        } else {
                          setFormData({ ...formData, interestedIn: selectedValues.filter((v) => v !== value).join(", ") });
                        }
                      }}
                    />
                    <label
                      htmlFor="interestedOption4"
                      className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                    >
                      <div className="flex justify-center">
                        <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                          <img
                            src="https://img.invupos.com/webinvu/confirmed%20demo/Correo%20marketing.svg"
                            className="w-14"
                            alt="Marketing por correo electrónico"
                          />
                        </div>
                      </div>
                      <p className="text-gray-800 text-base font-normal text-center">
                        Marketing por correo electrónico
                      </p>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="interestedOption5"
                      name="interestedIn"
                      value="Gestión de inventario"
                      className="peer hidden"
                      checked={formData.interestedIn.includes("Gestión de inventario")}
                      onChange={(e) => {
                        const value = e.target.value;
                        const selectedValues = formData.interestedIn.split(", ").filter(Boolean);
                        if (e.target.checked) {
                          setFormData({ ...formData, interestedIn: [...selectedValues, value].join(", ") });
                        } else {
                          setFormData({ ...formData, interestedIn: selectedValues.filter((v) => v !== value).join(", ") });
                        }
                      }}
                    />
                    <label
                      htmlFor="interestedOption5"
                      className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                    >
                      <div className="flex justify-center">
                        <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                          <img
                            src="https://img.invupos.com/webinvu/confirmed%20demo/Gestion%20de%20inventario.svg"
                            className="w-14"
                            alt="Gestión de inventario"
                          />
                        </div>
                      </div>
                      <p className="text-gray-800 text-base font-normal text-center">
                        Gestión de inventario
                      </p>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="interestedOption6"
                      name="interestedIn"
                      value="Lealtad y Fidelización de Clientes"
                      className="peer hidden"
                      checked={formData.interestedIn.includes("Lealtad y Fidelización de Clientes")}
                      onChange={(e) => {
                        const value = e.target.value;
                        const selectedValues = formData.interestedIn.split(", ").filter(Boolean);
                        if (e.target.checked) {
                          setFormData({ ...formData, interestedIn: [...selectedValues, value].join(", ") });
                        } else {
                          setFormData({ ...formData, interestedIn: selectedValues.filter((v) => v !== value).join(", ") });
                        }
                      }}
                    />
                    <label
                      htmlFor="interestedOption6"
                      className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                    >
                      <div className="flex justify-center">
                        <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                          <img
                            src="https://img.invupos.com/webinvu/confirmed%20demo/Lealtad.svg"
                            className="w-14"
                            alt="Lealtad y Fidelización de Clientes"
                          />
                        </div>
                      </div>
                      <p className="text-gray-800 text-base font-normal text-center">
                        Lealtad y Fidelización de Clientes
                      </p>
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="interestedOption7"
                      name="interestedIn"
                      value="Algo más"
                      className="peer hidden"
                      checked={formData.interestedIn.includes("Algo más")}
                      onChange={(e) => {
                        const value = e.target.value;
                        const selectedValues = formData.interestedIn.split(", ").filter(Boolean);
                        if (e.target.checked) {
                          setFormData({ ...formData, interestedIn: [...selectedValues, value].join(", ") });
                        } else {
                          setFormData({ ...formData, interestedIn: selectedValues.filter((v) => v !== value).join(", ") });
                        }
                      }}
                    />
                    <label
                      htmlFor="interestedOption7"
                      className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                    >
                      <div className="flex justify-center">
                        <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                          <img
                            src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg"
                            className="w-14"
                            alt="Algo más"
                          />
                        </div>
                      </div>
                      <p className="text-gray-800 text-base font-normal text-center">
                        Algo más
                      </p>
                    </label>
                  </div>
                </div>
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={() => {
                      setStep(formData.industryType === "Otros" ? 2 : 3);
                    }}
                    className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                  >
                    Atrás
                  </button>
                  <button
                    type="button"
                    onClick={handleNext}
                    className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </>
          );
        case 5: 
        return (
          <>
          {/* CANTIDAD DE COLABORADORES */}
          <div style={{ marginBottom: "15px" }}>
            <label className="block text-2xl font-medium text-gray-700 mb-8">
              Cantidad de colaboradores:
            </label>
            <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
              <div>
                <input
                  type="radio"
                  id="collaboratorsOption1"
                  name="collaboratorsCount"
                  value="Menos de 10"
                  className="peer hidden"
                  checked={formData.collaboratorsCount === "Menos de 10"}
                  onChange={handleChange}
                />
                <label
                  htmlFor="collaboratorsOption1"
                  className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                >
                  <div className="flex justify-center">
                    <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                      <img
                        src="https://img.invupos.com/webinvu/confirmed%20demo/Empleados%201.svg"
                        className="w-14"
                        alt="Menos de 10"
                      />
                    </div>
                  </div>
                  <p className="text-gray-800 text-base font-normal text-center">
                    Menos de 10
                  </p>
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="collaboratorsOption2"
                  name="collaboratorsCount"
                  value="Entre 11 y 50"
                  className="peer hidden"
                  checked={formData.collaboratorsCount === "Entre 11 y 50"}
                  onChange={handleChange}
                />
                <label
                  htmlFor="collaboratorsOption2"
                  className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                >
                  <div className="flex justify-center">
                    <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                      <img
                        src="https://img.invupos.com/webinvu/confirmed%20demo/Empleados%202.svg"
                        className="w-14"
                        alt="Entre 11 y 50"
                      />
                    </div>
                  </div>
                  <p className="text-gray-800 text-base font-normal text-center">
                    Entre 11 y 50
                  </p>
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="collaboratorsOption3"
                  name="collaboratorsCount"
                  value="Más de 50"
                  className="peer hidden"
                  checked={formData.collaboratorsCount === "Más de 50"}
                  onChange={handleChange}
                />
                <label
                  htmlFor="collaboratorsOption3"
                  className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                >
                  <div className="flex justify-center">
                    <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                      <img
                        src="https://img.invupos.com/webinvu/confirmed%20demo/Empleados%203.svg"
                        className="w-14"
                        alt="Más de 50"
                      />
                    </div>
                  </div>
                  <p className="text-gray-800 text-base font-normal text-center">
                    Más de 50
                  </p>
                </label>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={handleBack}
                className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              >
                Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              >
                Siguiente
              </button>
            </div>
          </div>
          </>
      );
      case 6:
        return (
          <>
            <div style={{ marginBottom: "15px" }}>
              <label className="block text-2xl font-medium text-gray-700 mb-8">
                ¿Cuál es su ocupación?
              </label>
              <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                <div>
                  <input
                    type="radio"
                    id="occupationOption1"
                    name="occupation"
                    value="Propietario"
                    className="peer hidden"
                    checked={formData.occupation === "Propietario"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="occupationOption1"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Propietario.svg"
                          className="w-14"
                          alt="Propietario"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Propietario
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="occupationOption2"
                    name="occupation"
                    value="Director General"
                    className="peer hidden"
                    checked={formData.occupation === "Director General"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="occupationOption2"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Director%20general.svg"
                          className="w-14"
                          alt="Director General"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Director General
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="occupationOption3"
                    name="occupation"
                    value="Consultor"
                    className="peer hidden"
                    checked={formData.occupation === "Consultor"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="occupationOption3"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Consultor.svg"
                          className="w-14"
                          alt="Consultor"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Consultor
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="occupationOption4"
                    name="occupation"
                    value="Operaciones"
                    className="peer hidden"
                    checked={formData.occupation === "Operaciones"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="occupationOption4"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Operaciones.svg"
                          className="w-14"
                          alt="Operaciones"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Operaciones
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="occupationOption5"
                    name="occupation"
                    value="IT"
                    className="peer hidden"
                    checked={formData.occupation === "IT"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="occupationOption5"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/IT.svg"
                          className="w-14"
                          alt="IT"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      IT
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="occupationOption6"
                    name="occupation"
                    value="Colaborador"
                    className="peer hidden"
                    checked={formData.occupation === "Colaborador"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="occupationOption6"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Colaborador.svg"
                          className="w-14"
                          alt="Colaborador"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Colaborador
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="occupationOption7"
                    name="occupation"
                    value="Otra"
                    className="peer hidden"
                    checked={formData.occupation === "Otra"}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="occupationOption7"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg"
                          className="w-14"
                          alt="Otra"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Otra
                    </p>
                  </label>
                </div>
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handleBack}
                  className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                >
                  Atrás
                </button>
                <button
                  type="button"
                  onClick={handleNext}
                  className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                >
                  Siguiente
                </button>
              </div>
            </div>
          </>
        );
      case 7:
        return (
          <>
            <div style={{ marginBottom: "15px" }}>
              <label className="block text-2xl font-medium text-gray-700 mb-8">
                Método preferido de contacto:
              </label>
              <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                <div>
                  <input
                    type="checkbox"
                    id="contactOption1"
                    name="preferredContactMethod"
                    value="Llamada Telefónica"
                    className="peer hidden"
                    checked={formData.preferredContactMethod.includes("Llamada Telefónica")}
                    onChange={(e) => {
                      const value = e.target.value;
                      const selectedValues = formData.preferredContactMethod.split(", ").filter(Boolean);

                      if (e.target.checked) {
                        setFormData({ ...formData, preferredContactMethod: [...selectedValues, value].join(", ") });
                      } else {
                        setFormData({ ...formData, preferredContactMethod: selectedValues.filter((v) => v !== value).join(", ") });
                      }
                    }}
                  />
                  <label
                    htmlFor="contactOption1"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Telephone.svg"
                          className="w-14"
                          alt="Llamada Telefónica"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Llamada Telefónica
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="contactOption2"
                    name="preferredContactMethod"
                    value="WhatsApp"
                    className="peer hidden"
                    checked={formData.preferredContactMethod.includes("WhatsApp")}
                    onChange={(e) => {
                      const value = e.target.value;
                      const selectedValues = formData.preferredContactMethod.split(", ").filter(Boolean);

                      if (e.target.checked) {
                        setFormData({ ...formData, preferredContactMethod: [...selectedValues, value].join(", ") });
                      } else {
                        setFormData({ ...formData, preferredContactMethod: selectedValues.filter((v) => v !== value).join(", ") });
                      }
                    }}
                  />
                  <label
                    htmlFor="contactOption2"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/WhatsApp.svg"
                          className="w-14"
                          alt="WhatsApp"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      WhatsApp
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="contactOption3"
                    name="preferredContactMethod"
                    value="Correo Electrónico"
                    className="peer hidden"
                    checked={formData.preferredContactMethod.includes("Correo Electrónico")}
                    onChange={(e) => {
                      const value = e.target.value;
                      const selectedValues = formData.preferredContactMethod.split(", ").filter(Boolean);
                      if (e.target.checked) {
                        setFormData({ ...formData, preferredContactMethod: [...selectedValues, value].join(", ") });
                      } else {
                        setFormData({ ...formData, preferredContactMethod: selectedValues.filter((v) => v !== value).join(", ") });
                      }
                    }}
                  />
                  <label
                    htmlFor="contactOption3"
                    className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                  >
                    <div className="flex justify-center">
                      <div className="w-20 mb-5 h-20 rounded-full flex justify-center items-center bg-sky-50">
                        <img
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Email.svg"
                          className="w-14"
                          alt="Correo Electrónico"
                        />
                      </div>
                    </div>
                    <p className="text-gray-800 text-base font-normal text-center">
                      Correo Electrónico
                    </p>
                  </label>
                </div>
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handleBack}
                  className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                >
                  Atrás
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-green-500 rounded-md hover:bg-green-400 focus:outline-none focus:ring focus:ring-green-300 focus:ring-opacity-50"
                >
                  Enviar
                </button>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
          <div style={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
            <h1>Crear Organización, Contacto y Lead en Pipedrive</h1>
            {renderStep()}
            {responseMessage && (
              <p style={{ marginTop: '20px', color: responseMessage.includes('Error') ? 'red' : 'green' }}>
                {responseMessage}
              </p>
            )}
          </div>
            <div
            class="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    
            <div class="fixed inset-0 z-10 overflow-y-auto">
              <section class="min-h-screen  bg-white lg:flex">
                <div class="flex flex-col justify-end px-8 bg-gradient-to-b from-pirLightBlue to-pirPurple lg:px-12 xl:px-24 lg:w-1/2">
                  <div className="hidden lg:block">
                    <div>
                      <p className="inline-block px-3 py-1 mb-3 text-xs  mx-auto font-bold tracking-wider uppercase rounded-full bg-#2A72FF border-white border">
                        <span className="text-white">Invu POS</span>
                      </p>
                      <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-white sm:text-[43px] sm:leading-none">
                      ¡Queremos conocerte mejor!
                      </h2>
                      <p className="pr-5 mb-2 text-md text-white opacity-90">
                      Tu negocio es único y merece una solución a medida. Llena el formulario adicional para que podamos entender mejor tus necesidades y ofrecerte una experiencia de servicio verdaderamente personalizada.{" "}
                        <span className="font-semibold italic">
                        ¡Tu éxito es nuestra prioridad!
                        </span>
                      </p>
                    </div>
    
                    <Carousel
                      className="z-10 relative ss"
                      autoPlay={true}
                      showThumbs={false}
                      showArrows={false}
                      infiniteLoop={true}
                      interval={6000}
                      swipeable={false}
                      showStatus={false}
                      showIndicators={false}
                      swipeAnimationHandler={false}
                      animationHandler="fade"
                      handleTransition="fade"
                    >
                      <div>
                        <img
                          className="self-end "
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Hero-confirmed-1.webp"
                          alt="Ecommerce Hero"
                          loading="lazy"
                          style={{ width: "540px", height: "auto" }}
                        />
                      </div>
                      <div>
                        <img
                          className="self-end "
                          src="https://img.invupos.com/webinvu/confirmed%20demo/Hero%20confirmed%204.png"
                          alt="Ecommerce Hero"
                          loading="lazy"
                          style={{ width: "540px", height: "auto" }}
                        />
                      </div>
                    </Carousel>
                  </div>
                </div>
    
                <div class="flex flex-col justify-center w-full p-5 pt-0 lg:w-1/2 lg:px-12 xl:px-12 bg-[url('https://img.invupos.com/webinvu/confirmed%20demo/step%20one%20icons/Bg-abstract-form%281%29.png')]">
                  <div className="mb-14 mt-10 lg:mt-0 ">
                    <img
                      className="h-[65px] w-auto"
                      src="https://res.cloudinary.com/dslkvd23s/image/upload/v1648001932/Branding%20Invu%20/Logo%20Invu/logo-INVU-POS-rgb_nrg6ry.png"
                      alt=""
                    />
                  </div>
                  

                    {renderStep()}
                    {responseMessage && (
                      <div className="max-w-lg bg-white border border-gray-200 rounded-xl shadow-lg" role="alert" tabIndex="-1" aria-labelledby="toast-message">
                        <div className="flex p-4">
                          <div className="shrink-0 mr-2">
                            {responseMessage.includes('Error') ? (
                              <svg className="shrink-0 size-4 text-red-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                              </svg>
                            ) : (
                              <svg className="shrink-0 size-4 text-teal-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                            )}
                          </div>
                          <div className="ms-3">
                            <p id="toast-message" className="text-sm text-gray-700 dark:text-neutral-400">
                              {responseMessage}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {Object.keys(errors).length > 0 && (
                        <div className="mt-4 text-red-500 text-sm">
                          {Object.values(errors).map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                        </div>
                    )}
                  
                </div>
              </section>
            </div>
          </div>
    </>
  );
};

export default Contact_pipedrive;








