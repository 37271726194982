import React from 'react'
import { Helmet } from "react-helmet";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";

const Article_invupos_the_software_chosen_by_imin_for_the_nrf = () => {
    useDocumentTitle(
        "Aumenta tus ventas con Invu POS en El Salvador | Invu POS"  
    ); 
  return (
      <>
       <Helmet>
     <meta
       name="description"
       content="Ciudad de Panamá, Viernes 10 de Enero del 2025– INVU POS se enorgullece de anunciar su participación en el evento más importante de la industria del retail, el National Retail Federation (NRF) Retail’s Big Show 2025, que se llevará a cabo en Nueva York del 14 al 16 de enero."
     />
     <meta name="keywords" content="INVU POS, el software elegido por iMin para el Retail’s Big Show 2025, el evento mas grande para la industria de minoristas a nivel mundial." />
     </Helmet>
     <article class="px-4 py-24 mx-auto max-w-7xl -mt-14" itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
         <div class="w-full mx-auto mb-12 text-left md:w-3/4 lg:w-1/2">
             <div className="flex space-x-3 mb-2">
             <p class="mt-6 mb-4 py-2 px-5 rounded-full text-xs font-semibold tracking-widest uppercase text-[#6D28D9] bg-[#f8f3ff]">Nota de Prensa</p>
             <p class="mt-6 mb-4 py-2 px-5 rounded-full text-xs font-semibold tracking-widest uppercase text-[#6D28D9] bg-[#f8f3ff]">Artículo</p>
             </div>
             <h1 class="mb-6 text-3xl font-bold leading-tight text-gray-900 md:text-4xl" itemprop="headline">
             INVU POS, el software elegido por iMin para el NRF 2025
             </h1>
             <p class="text-base text-gray-500 italic">Fecha de publicación — <span className="font-semibold">January 10, 2025</span></p>
             <div class="pb-6 mb-6 border-b border-gray-200"></div>
             <p className="text-gray-700 leading-7 mb-6">
             Ciudad de Panamá, Viernes 10 de Enero del 2025– INVU POS se enorgullece de anunciar su participación en el evento más importante de la industria del retail, el National Retail Federation (NRF) Retail’s Big Show 2025, que se llevará a cabo en Nueva York del 14 al 16 de enero.
             </p>
             <div class="order-2 h-72 md:order-1 md:h-full rounded-xl">
             <img
                 src="https://img.invupos.com/webinvu/Blog%20invu/invu-pos-el-software-elegido-por-imin-para-el-nrf-2025.jpg"
                 class="w-full h-full bg-center object-cover rounded-xl"
                 alt=""
             />
             </div>
     
             <a class="flex items-center text-gray-700 mt-6" href="#">
             <div class="gradient-div"></div>
             <div class="ml-2">
                 <p class="text-medium font-semibold text-gray-800">De: Invu POS</p>
             </div>
             </a>
             <div class="pb-6 mb-6 border-b border-gray-200"></div>
     
         </div>
     
         <div class="w-full mx-auto prose md:w-3/4 lg:w-1/2">
   
         
            <p className="mt-4 text-gray-700 leading-7">
            <span className='font-semibold'>iMin</span>, líder en dispositivos innovadores para negocios, ha seleccionado a <span className='font-semibold'>INVU POS</span> como su software oficial para mostrar la capacidad y funcionalidad de sus equipos durante el evento. Esta colaboración destaca el compromiso de ambas empresas con la innovación tecnológica y el desarrollo de soluciones que transforman la experiencia en el punto de venta.
            </p>
   
            <p className="mt-4 text-gray-700 leading-7 italic">
            "Estamos emocionados de que iMin haya confiado en INVU POS para ser parte de su demostración en el NRF 2025," afirmó Rafael Turgman - CEO. "Este es un reconocimiento a la robustez de nuestro software y a nuestra visión de transformar la industria del retail con herramientas inteligentes y eficaces."
            </p>
          
            <p className="mt-4 text-gray-700 leading-7">
            Durante el <span className='font-semibold'>NRF 2025</span>, los asistentes podrán explorar de primera mano cómo la combinación de los equipos de iMin y el software de INVU POS ofrece una solución integral para negocios de cualquier tamaño, optimizando la gestión de ventas, inventarios y estrategias de fidelización de clientes.
            </p>
   
            <p className="mt-4 text-gray-700 leading-7">
            El <span className='font-semibold'>Retail’s Big Show</span> es el evento más relevante del sector, reuniendo a líderes de la industria, expertos en tecnología y empresas visionarias que buscan redefinir el futuro del comercio minorista.
            </p>

            <p className="mt-4 text-gray-700 leading-7">
            Para más información sobre INVU POS y su participación en el NRF 2025, visita nuestro sitio web o síguenos en nuestras redes sociales.
            </p>
   
            <img src="https://img.invupos.com/webinvu/Blog%20invu/IMIN-LANDSCAPE.jpg" class="object-cover  w-full h-96 bg-center rounded-lg mt-8" alt="" />
     
            <h2 className="font-semibold text-xl mt-12">
            Sobre iMin
            </h2>
            <p className="mt-4 text-gray-700 leading-7">
            iMin es una empresa global dedicada al desarrollo de hardware innovador para negocios, diseñando equipos que combinan funcionalidad, diseño y rendimiento. Su tecnología impulsa la productividad y mejora la experiencia tanto para negocios como para consumidores.
            </p>

             <div class="pb-6 mb-6 border-b border-gray-200"></div>
             <div class="flex items-center mb-6 space-x-2">
             <p class="text-base text-gray-500">
             January 10, 2025 — Written by:{" "}
                 <span className="underline font-regular">Andres Amaya</span> 
             </p>
             </div>
         </div>
     </article>
    </>
  )
}

export default Article_invupos_the_software_chosen_by_imin_for_the_nrf