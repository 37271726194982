import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Redirect, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const Contact_sv = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");
  const [registrationId, setRegistrationId] = useState(null);

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const leadStatus = "Nuevo"
  

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const history = useHistory();

  const location = useLocation();
  const prefix = useLocalePrefix();
  const [countryRender, setCountryRender] = useState('');
  const [loading, setLoading] = useState(true); 

  const [geoInfo, setGeoInfo] = useState({
    ip: '',
    countryName: '',
    countryCode: '',
    city: '',
    timezone: ''
  });

  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        setGeoInfo({
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone
        });
        setCountryRender(data.country_name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useEffect(() => {
    getGeoInfo();
  }, []);

  if (loading) {
    return <div></div>;
  }

  function generateUUID() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  var hutkValue = generateUUID();

  const handleSubmit = (evt) => {
    evt.preventDefault();

    
    var xhr = new XMLHttpRequest();
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/3797225/be316fe8-ea4c-4aa2-ad2a-b5750f006e01";

    var data = {
      fields: [
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "email",
          value: email,
        },

        {
          name: "mobilephone",
          value: phone,
        },
        {
          name: "name",
          value: name,
          objectTypeId: "COMPANY",
        },
        {
          name: "message",
          value: message,
        },
        {
          name: "hs_lead_status",
          value: leadStatus,
          objectTypeId: "CONTACT",
        }
      ],
      context: {
        hutk: hutkValue,
        ipAddress: geoInfo.ip,
        pageUri: "www.invupos.com",
        pageName: "formulario de contacto invu pos"
      },
    };
    console.log(data);
    var final_data = JSON.stringify(data);

    xhr.open("POST", url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const responseData = JSON.parse(xhr.responseText);
        const registrationId = responseData.inlineMessage;

        history.push(`${prefix}/request-demo-confirmed`, {
          firstName,
          lastName,
          email,
          phone,
          name,
          message,
          leadStatus,
          registrationId,
          hutkValue,
        });
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        //TODO @YannickAlexander poner el toster bonito si responde un arreglo xhr.data.errors
        // alert(xhr.responseText);
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1651769707/Branding%20Invu%20/Logo%20Apps/Layer_2_pxylmq.png"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Invu POS</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-red-600">Error</span>{" "}
                    Bad request ❌
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
      }
    };
    xhr.send(final_data);
    clearForm();
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setName("");
    setIsChecked(false); // Limpia el checkbox
  };

  return (
    <>
      <section id="contactus" class="bg-gray-50">
        <div class="max-w-screen-xl px-4  py-20 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div class="lg:col-span-2">
              <div class="w-10 mb-4 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center items-center"></div>
              <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg ">
                {t("contacttittlenew")}
              </h2>
              <p className="text-lg max-w-sm text-gray-500 md:text-medium">
                {t("contactdescriptionnew")}
              </p>
              <div class="mt-8">
                <div>
                  <h3 class="text-lg font-semibold text-black">
                    {" "}
                    {t("contactcelltittle")}
                  </h3>
                  <span class="block w-10 h-1 mt-1  bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple "></span>
                  <p class="max-w-xs mt-4 text-lg text-gray-500">
                    +503 7690 0658
                  </p>
                </div>
              </div>
              <div class="mt-8">
                <div>
                  <h3 class="text-lg font-semibold text-black">
                    {t("contactemailtittle")}
                  </h3>
                  <span class="block w-10 h-1 mt-1  bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple "></span>
                  <p class="max-w-xs mt-4 text-lg text-gray-500">
                  {/* alejandra@invupos.com */}
                  info@invupos.com
                  </p>
                </div>
              </div>
              <div class="mt-8">
                <div>
                  <h3 class="text-lg font-semibold text-black">
                    {" "}
                    {t("contactlocationtittle")}
                  </h3>
                  <span class="block w-10 h-1 mt-1  bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple"></span>
                  <p class="max-w-xl mt-4  text-lg text-gray-500">
                    {t("contactlocationdescriptionsv")}
                  </p>
                </div>
              </div>
            </div>

            <div class="p-8 bg-white rounded-lg shadow-lg lg:p-12 lg:mt-14 lg:col-span-3">
              <form onSubmit={handleSubmit} class="space-y-4">
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label class="sr-only">First name</label>
                    <input
                      class="w-full p-4 text-sm border border-gray-300 bg-gray-50 appearance-none focus:outline-none focus:ring-2 focus:ring-gray-300 focus:shadow-outline rounded"
                      placeholder={t("contactfirstname")}
                      required
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div>
                    <label class="sr-only">Last Name</label>
                    <input
                      class="w-full p-4 text-sm border border-gray-300 bg-gray-50 appearance-none focus:outline-none focus:ring-2 focus:ring-gray-300 focus:shadow-outline rounded"
                      placeholder={t("contactlastname")}
                      required
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>

                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label class="sr-only">Email</label>
                    <input
                      class="w-full p-4 text-sm border border-gray-300 bg-gray-50 appearance-none focus:outline-none focus:ring-2 focus:ring-gray-300 focus:shadow-outline rounded"
                      placeholder={t("contactemail")}
                      required
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div>
                    <label class="sr-only">Phone</label>
                    <PhoneInput
                      className="w-full p-4 text-sm border border-gray-300 bg-gray-50 appearance-none focus:outline-none focus:ring-2 focus:ring-gray-300 focus:shadow-outline rounded"
                      placeholder={t("modalphone")}
                      defaultCountry="SV"
                      required
                      value={phone}
                      onChange={(value) => setPhone(value)}
                    />
                  </div>
                </div>



                <div>
                  <label class="sr-only">Company</label>
                  <input
                    class="w-full p-4 text-sm border border-gray-300 bg-gray-50 appearance-none focus:outline-none focus:ring-2 focus:ring-gray-300 focus:shadow-outline rounded"
                    placeholder={t("contactcompany")}
                    required
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div>
                  <label class="sr-only">Message</label>
                  <textarea
                    class="w-full p-3 text-sm border border-gray-300 bg-gray-50 appearance-none focus:outline-none focus:ring-2 focus:ring-gray-300 focus:shadow-outline rounded"
                    placeholder={t("contactmessage")}
                    rows="8"
                    required
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>

                    <div class="mt-3 mb-8 text-left text-gray-600 dark:text-gray-400 flex items-center justify-between">
                      <div class="flex items-center">
                        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} placeholder="Privacy Policy" required class="w-5 h-5  text-blue-600 border-gray-200 rounded focus:ring-blue-500"/>
                        <label  class="block ml-2 text-[14px] text-neutral-600"> {t("privacypolicytext1")} {" "}
                        <Link
                          className={`underline font-bold ${countryRender === 'Mexico' ? 'text-green-700' : countryRender === 'Venezuela' ? 'text-[#001F7E]' : 'text-indigo-600'}`}
                          to={`${[prefix]}` + "/politicas-de-privacidad"}

                        >
                          {t("privacypolicytexthref2")}
                        </Link>
                          {" "}
                            {t("privacypolicytext3")} </label>
                      </div>
                    </div>
                </div>


                <div class="mt-4">
                <button
                    type="submit"
                    value="Submit"
                    className={`inline-flex items-center w-full justify-center md:w-full lg:w-full px-5 py-4 ${countryRender === 'Mexico' ? 'bg-green-700 text-white' : countryRender === 'Venezuela' ? 'text-[#000000] bg-[#FED850]' : 'bg-indigo-600 text-white'} hover:opacity-90 rounded sm:w-full`}
                  >
                    <span className="font-medium">{t("contactformbutton")}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 ml-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </button>
                </div>
              </form>
              <Toaster position="bottom-center" reverseOrder={false} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact_sv;