import React from 'react'
import Customers_carousel from "../../components/modules/Customers_carousel";
import CTA_home from './CTA_home';
import Industries_tabs_main from './Industries/Industries_tabs_main';
import Hardware_equipment from './Hardware_equipment';
import Support from './Support';
import Latest_blog from '../../components/Home/Latest_blog';
import Members from '../../components/Home/Members';
import Industries_carousel from '../../components/modules/Industries_carousel';
import HeroRD from './HeroRD';
import Products_main_tabs from '../landingRD/Products_tabs/Products_main_tabs';
import Contact_rd from '../../components/Home/Contact_rd';

const LandingRD = () => {
  return (
    <>
        <HeroRD/>
        <Products_main_tabs/>
        {/* <Customers_carousel/> */}
        <Industries_carousel/>
        <CTA_home/>
        <Industries_tabs_main/>
        <Hardware_equipment/>
        <Support/>
        <Latest_blog/>
        <Members/>
        <Contact_rd  />
    </>
  )
}

export default LandingRD