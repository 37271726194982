import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../../../components/utils/useLocalePrefix";
import Modal_started from "../../../components/Home/Modal_started";


const Kiosks_tab = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="mt-4 max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            {/* <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                viewBox="0 0 20 20"
                fill="#fff"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                  clip-rule="evenodd"
                />
                <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
              </svg>
            </div> */}
            <div className="max-w-xl mb-6">
              <h3 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                {t("kioskstittle")}
              </h3>
              <p className="text-gray-600 leading-loose">
                {t("kiosksdescription")}{" "}
                <Link
                  to={`${prefix}` + "/productos/quioscos"}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <span className="underline font-semibold cursor-pointer">
                    {t("kiosksspan")}
                  </span>
                </Link>
              </p>
              <div class="flex space-y-1 flex-col mt-3 flex-grow ">
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#266aa3"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("kiosksitem1")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#266aa3"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("kiosksitem2")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#266aa3"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("kiosksitem3")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#266aa3"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("kiosksitem4")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#266aa3"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("kiosksitem5")}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div class="flex flex-wrap gap-4 text-center mt-2">
                <button
                  class="inline-flex items-center justify-center  block w-full px-8 py-3 text-sm font-medium text-white rounded shadow bg-[#4997d0]  sm:w-auto hover:bg-opacity-95  focus:outline-none focus:ring"
                  onClick={() => setIsOpen(true)}
                >
                  <span className="mr-3">{t("pointofsalebutton1")}</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      points="4,4 22,4 19,14 4,14 "
                    />
                    <circle
                      cx="4"
                      cy="22"
                      r="2"
                      strokeLinejoin="miter"
                      strokeLinecap="square"
                      stroke="none"
                      fill="currentColor"
                    />
                    <circle
                      cx="20"
                      cy="22"
                      r="2"
                      strokeLinejoin="miter"
                      strokeLinecap="square"
                      stroke="none"
                      fill="currentColor"
                    />
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      points="1,1 4,4 4,14 2,18 23,18 "
                    />
                  </svg>
                </button>

                <Link
                  class="block w-full px-2  py-3 text-sm font-medium bg-white rounded text-[#266aa3] hover:text-opacity-80 sm:w-auto active:text-indigo-500 focus:outline-none focus:ring"
                  to={`${prefix}` + "/productos/quioscos"}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("pointofsalebutton2")}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <img
              className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
              src="https://img.invupos.com/webinvu/Invu%20Products/Kiosks/Kiosks%201.jpg"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Kiosks_tab;
