import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useLocalePrefix from "../../utils/useLocalePrefix";
import Modal_started from "../../Home/Modal_started";

const ModalContent = ({ setIsOpenModal, iframeSrc, title }) => {
  const { t } = useTranslation();
  const [height, setHeight] = useState('100vh');

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setHeight('45vh');
      } else if (width <= 640) {
        setHeight('50vh');
      } else if (width <= 768) {
        setHeight('60vh');
      } else if (width <= 1024) {
        setHeight('80vh');
      } else {
        setHeight('100vh');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative mt-12 bg-black py-8">
        {/* Botón de cierre más visible y dentro de un círculo */}
        <button 
        onClick={() => setIsOpenModal(false)} 
        className="absolute top-10 right-8 flex items-center justify-center w-12 h-12 bg-gray-800 rounded-full focus:outline-none"
        >
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="w-8 h-8 text-white" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
            strokeWidth="2"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </button>
        <h2 className="text-xl font-semibold text-center text-white relative top-10">{title}</h2>
        <iframe
          style={{
            border: 'none',
            width: '100vw',
            height: height,
            margin: 0,
            padding: 0,
            outline: 'none' 
          }}
          src={iframeSrc}
          allowFullScreen
        />
      </div>
    </div>
  );
};

const Costos = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  const handleOpenModal = (src, title) => {
    setIframeSrc(src);
    setModalTitle(title);
    setIsOpenModal(true);
  };

  const carouselRef = useRef(null);

  const handleScroll = (direction) => {
    const scrollAmount = 300; // Cantidad de píxeles para desplazarse
    if (carouselRef.current) {
      if (direction === 'left') {
        carouselRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
      } else if (direction === 'right') {
        carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    }
  };


  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      {isOpenModal && <ModalContent setIsOpenModal={setIsOpenModal} iframeSrc={iframeSrc} title={modalTitle} />}
      <div className="relative mt-4 max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 bg-white rounded-3xl">
      {/* Botón izquierdo */}
      <button
        className="hidden md:flex absolute left-2 top-1/2 transform -translate-y-1/2 z-10 w-10 h-10 items-center justify-center bg-gray-800 text-white rounded-full hover:bg-gray-600 shadow-lg"
        onClick={() => handleScroll('left')}
      >
        ←
      </button>

      <div
        ref={carouselRef}
        className="flex space-x-4 overflow-x-auto px-4 scrollbar-hideee"// Ajustado para scroll en mobile
      >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal('https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0fSE6NkIcJfWM7lrtwT6tD%2FDemosnew%3Fnode-id%3D12-5364%26p%3Df%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D12%253A5315&hide-ui=1', 'Cómo cobrar a un cliente');
            }}
            aria-label="View Item"
            className="inline-block w-[300px] flex-shrink-0 overflow-hidden duration-300 transform bg-white rounded-2xl shadow-sm hover:-translate-y-2"
          >
            <div className="flex flex-col h-full">
              <img
                src="https://img.invupos.com/webinvu/Demos%20Invu/Cover%20cobros%201.png"
                className="object-cover w-full h-50"
                alt=""
              />
              <div className="flex-grow border border-t-0 rounded-b-2xl">
                <div className="p-5">
                  <h2 className="mb-2 font-semibold leading-5 text-lg">
                    {t("supportsection3card1title")}
                  </h2>
                  <p className="text-md text-gray-900">
                    {t("supportsection3card1description")}
                  </p>
                </div>
              </div>
            </div>
          </a>



          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal('https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0fSE6NkIcJfWM7lrtwT6tD%2FDemosnew%3Fnode-id%3D12-12209%26p%3Df%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D12%253A5317&hide-ui=1', ' Division de cuentas por productos');
            }}
            aria-label="View Item"
            className="inline-block w-[300px] flex-shrink-0 overflow-hidden duration-300 transform bg-white rounded-2xl shadow-sm hover:-translate-y-2"
          >
            <div className="flex flex-col h-full">
              <img
                src="https://img.invupos.com/webinvu/Demos%20Invu/Cover%20Division%20de%20cuentas%20por%20producto%281%29.png"
                className="object-cover w-full h-50"
                alt=""
              />
              <div className="flex-grow border border-t-0 rounded-b-2xl">
                <div className="p-5">
                  <h2 className="mb-2 font-semibold leading-5 text-lg">
                    {t("supportsection3card3title")}
                  </h2>
                  <p className="text-md text-gray-900">
                    {t("supportsection3card3description")}
                  </p>
                </div>
              </div>
            </div>
          </a>
          


          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal('https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0fSE6NkIcJfWM7lrtwT6tD%2FDemosnew%3Fnode-id%3D12-14284%26p%3Df%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D12%253A5318&hide-ui=1', 'Division equitativa');
            }}
            aria-label="View Item"
            className="inline-block w-[300px] flex-shrink-0 overflow-hidden duration-300 transform bg-white rounded-2xl shadow-sm hover:-translate-y-2"
          >
            <div className="flex flex-col h-full">
              <img
                src="https://img.invupos.com/webinvu/Demos%20Invu/Division%20equitativa.png"
                className="object-cover w-full h-50"
                alt=""
              />
              <div className="flex-grow border border-t-0 rounded-b-2xl">
                <div className="p-5">
                  <h2 className="mb-2 font-semibold leading-5 text-lg">
                    {t("supportsection3card4title")}
                  </h2>
                  <p className="text-md text-gray-900">
                    {t("supportsection3card4description")}
                  </p>
                </div>
              </div>
            </div>
          </a>

          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal('https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0fSE6NkIcJfWM7lrtwT6tD%2FDemosnew%3Fnode-id%3D12-18605%26p%3Df%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D12%253A5319&hide-ui=1', ' Aplicar descuento a la orden');
            }}
            aria-label="View Item"
            className="inline-block w-[300px] flex-shrink-0 overflow-hidden duration-300 transform bg-white rounded-2xl shadow-sm hover:-translate-y-2"
          >
            <div className="flex flex-col h-full">
              <img
                src="https://img.invupos.com/webinvu/Demos%20Invu/Aplicar%20descuento%20a%20la%20orden.png"
                className="object-cover w-full h-50"
                alt=""
              />
              <div className="flex-grow border border-t-0 rounded-b-2xl">
                <div className="p-5">
                  <h2 className="mb-2 font-semibold leading-5 text-lg">
                    {t("supportsection3card5title")}
                  </h2>
                  <p className="text-md text-gray-900">
                    {t("supportsection3card5description")}
                  </p>
                </div>
              </div>
            </div>
          </a>

          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal('https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0fSE6NkIcJfWM7lrtwT6tD%2FDemosnew%3Fnode-id%3D12-23420%26p%3Df%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D12%253A5320&hide-ui=1', ' Nota de Crédito Parcial');
            }}
            aria-label="View Item"
            className="inline-block w-[300px] flex-shrink-0 overflow-hidden duration-300 transform bg-white rounded-2xl shadow-sm hover:-translate-y-2"
          >
            <div className="flex flex-col h-full">
              <img
                src="https://img.invupos.com/webinvu/Demos%20Invu/Nota%20de%20Cr%C3%A9dito%20Parcial.png"
                className="object-cover w-full h-50"
                alt=""
              />
              <div className="flex-grow border border-t-0 rounded-b-2xl">
                <div className="p-5">
                  <h2 className="mb-2 font-semibold leading-5 text-lg">
                    {t("supportsection3card6title")}
                  </h2>
                  <p className="text-md text-gray-900">
                    {t("supportsection3card6description")}
                  </p>
                </div>
              </div>
            </div>
          </a>

          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal('https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0fSE6NkIcJfWM7lrtwT6tD%2FDemosnew%3Fnode-id%3D12-23808%26p%3Df%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D12%253A5321&hide-ui=1', 'Mover de una Mesa a Otra');
            }}
            aria-label="View Item"
            className="inline-block w-[300px] flex-shrink-0 overflow-hidden duration-300 transform bg-white rounded-2xl shadow-sm hover:-translate-y-2"
          >
            <div className="flex flex-col h-full">
              <img
                src="https://img.invupos.com/webinvu/Demos%20Invu/Mover%20de%20una%20mesa%20a%20otra.png"
                className="object-cover w-full h-50"
                alt=""
              />
              <div className="flex-grow border border-t-0 rounded-b-2xl">
                <div className="p-5">
                  <h2 className="mb-2 font-semibold leading-5 text-lg">
                    {t("supportsection3card7title")}
                  </h2>
                  <p className="text-md text-gray-900">
                    {t("supportsection3card7description")}
                  </p>
                </div>
              </div>
            </div>
          </a>

          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal('https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0fSE6NkIcJfWM7lrtwT6tD%2FDemosnew%3Fnode-id%3D12-25211%26p%3Df%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D12%253A5322&hide-ui=1', 'Aplicar Nota de Crédito Parcial');
            }}
            aria-label="View Item"
            className="inline-block w-[300px] flex-shrink-0 overflow-hidden duration-300 transform bg-white rounded-2xl shadow-sm hover:-translate-y-2"
          >
            <div className="flex flex-col h-full">
              <img
                src="https://img.invupos.com/webinvu/Demos%20Invu/Aplicar%20nota%20parcial%202.png"
                className="object-cover w-full h-50"
                alt=""
              />
              <div className="flex-grow border border-t-0 rounded-b-2xl">
                <div className="p-5">
                  <h2 className="mb-2 font-semibold leading-5 text-lg">
                    {t("supportsection3card8title")}
                  </h2>
                  <p className="text-md text-gray-900">
                    {t("supportsection3card8description")}
                  </p>
                </div>
              </div>
            </div>
          </a>

          {/* Botón derecho */}
          <button
          className="hidden md:flex absolute right-2 top-1/2 transform -translate-y-1/2 z-10 w-10 h-10 items-center justify-center bg-gray-800 text-white rounded-full hover:bg-gray-600 shadow-lg"
          onClick={() => handleScroll('right')}
          >
            →
          </button>

        </div>
      </div>
    </>
  );
};

export default Costos;
