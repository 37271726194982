import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

// const notify = () => toast.success("Successfully toasted!");

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../useDocumentTitle";

const Thanks_sending_pipedrive = () => {


  const location = useLocation();
  const prefix = useLocalePrefix();

  const { t, i18n } = useTranslation();

  return (
    <>
      <section>
        <div class="max-w-screen-xl lg:-mt-10 px-4 py-16 mx-auto sm:px-6 lg:px-8 sm:py-24">
          <div class="grid grid-cols-1 gap-8   lg:gap-16 lg:grid-cols-2">
            <div class="relative h-96 overflow-hidden sm:h-80 lg:h-[580px]">
              <img
                class="absolute inset-0 object-cover w-full h-full"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/v1656703912/Branding%20Invu%20/Thanks%20sending/Thanks_sending_n6say6.png"
                alt="Man using a computer"
              />
            </div>

            <div class="lg:py-40">
              <article class="space-y-4 text-gray-600">
                <h2 class="text-3xl max-w-2xl font-bold sm:text-2xl">
                  {t("thankstittle")}
                </h2>
                <p>{t("thanksdescription")}</p>
                <button
                    onClick={() => {
                        window.location.href = "/";
                        window.scrollTo(0, 0);
                    }}
                    className="inline-flex items-center px-8 py-3 mt-10 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                    >
                    <span className="text-sm font-medium">Ir a la Página</span>
                    <svg
                        className="w-5 h-5 ml-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                    </svg>
                </button>
              </article>
            </div>
          </div>
        </div>
      </section>

      {/* <Toaster position="bottom-center" reverseOrder={false} /> */}
    </>
  );
};

export default Thanks_sending_pipedrive;
