import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Food_and_drinks_tab from "./Food_and_drinks_tab";
import Retail_tab from "./Retail_tab";
import Professional_services_tab from "./Professional_services_tab";
import Entertainment_tab from "./Entertainment_tab";
import Large_companies_tab from "./Large_companies_tab";

const Industries_tabs_main = () => {
  const [openTab, setOpenTab] = React.useState(1);

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };
  return (
    <>
      <section>
        <div class="max-w-screen-xl px-4 mt-28 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
                {/* <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                {t("industriesuppercase1")}
                </p> */}
              </div>
              <h2 className="max-w-4lg mb-2 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              {t("industriestittle1")}
              </h2>
              {/* <p className="text-base text-gray-700 md:text-base">
              {t("industriesdescription1")}
              </p> */}
            </div>
          </div>
        </div>
      </section>
      <div className="flex flex-wrap">
        <div className="container max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8  ">
          <ul
            className="flex pt-3 pb-4 mb-0 overflow-x-scroll bg-gray-50 shadow-xl rounded-xl p-1.5 flex hide-scrollbar"
            role="tablist"
          >
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 1
                    ? "bg-[#4997d0] text-white text-xs shadow-lg"
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="mr-1 text-base w-full fas fa-space-shuttle"></i>
                {t("industriestab1")}
              </a>
            </li>
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase  px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 2
                    ? "bg-[#4997d0] text-white text-xs shadow-lg"
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="mr-1 text-base w-full fas fa-space-shuttle"></i>
                {t("industriestab2")}
              </a>
            </li>
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase  px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 3
                    ? "bg-[#4997d0] text-white text-xs shadow-lg"
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="mr-1 text-base w-full fas fa-space-shuttle"></i>
                {t("industriestab3")}
              </a>
            </li>
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase  px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 4
                    ? "bg-[#4997d0] text-white text-xs shadow-lg"
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="mr-1 text-base w-full fas fa-space-shuttle"></i>
                {t("industriestab4")}
              </a>
            </li>
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase  px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 5
                    ? "bg-[#4997d0] text-white text-xs shadow-lg"
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(5);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="mr-1 text-base w-full fas fa-space-shuttle"></i>
                {t("industriestab5")}
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col w-full mb-6 break-words rounded-b-lg ">
            <div className="flex-auto rounded-b-lg bg-pirGray-1800">
              <div className="tab-content tab-space ">
                <div
                  className={openTab === 1 ? "block " : "hidden "}
                  bg-pink-100
                  id="link1"
                >
                    <Food_and_drinks_tab/>
                </div>
                <div
                  className={openTab === 2 ? "block " : "hidden "}
                  bg-pink-100
                  id="link1"
                >
                    <Retail_tab/>
                </div>
                <div
                  className={openTab === 3 ? "block " : "hidden "}
                  bg-pink-100
                  id="link1"
                >
                    <Professional_services_tab/>
                </div>
                <div
                  className={openTab === 4 ? "block " : "hidden "}
                  bg-pink-100
                  id="link1"
                >
                    <Entertainment_tab/>
                </div>
                <div
                  className={openTab === 5 ? "block " : "hidden "}
                  bg-pink-100
                  id="link1"
                >
                    <Large_companies_tab/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TabsRender() {
  return (
    <>
      <Industries_tabs_main color="pink" />
    </>
  );
}
