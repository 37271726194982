import React, { useState, useEffect } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import useDocumentTitle from "../../useDocumentTitle";

import { ThreeDots } from "react-loader-spinner";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const BlogGT = () => {
  useDocumentTitle("Invu POS | Blog Resources");

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedTypeContent, setSelectedTypeContent] = useState("All");
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [filteredData, setFilteredData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleFilterButtonClick = () => {
    setIsLoading(true);
    const resultsSection = document.getElementById("results-section");
    resultsSection.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Simula una carga de 2 segundos
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const data = [
      {
        id: 1,
        name: "¡Haz que tu restaurante en Guatemala venda más con un POS moderno!",
        description:
          "En la industria restaurantera guatemalteca, la competencia es fuerte y los clientes buscan rapidez, buen servicio y opciones de pago cómodas. Si tienes un restaurante, café o comedor, sabes que cada detalle cuenta para atraer y retener clientes. Aquí es donde entra en juego un software POS en la nube: una herramienta que te ayuda a gestionar tu negocio con eficiencia, aumentar tus ventas y mejorar la experiencia del cliente.",
        date: "March 20, 2025",
        views: "2.3k",
        comments: "27",
        href: "/blog/haz-que-tu-restaurante-en-guatemala-venda-mas-con-un-pos-moderno",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/haz-que-tu-restaurante-en-guatemala-venda-mas-con-un-pos-moderno.jpeg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Guatemala", 
      },
      {
        id: 2,
        name: "KDS Incorporado con POS: La Clave para una Cocina Eficiente",
        description:
          "En la industria gastronómica, la rapidez y organización en la cocina son esenciales para ofrecer una excelente experiencia al cliente. Un KDS (Kitchen Display System) integrado con un sistema POS optimiza el flujo de trabajo en la cocina, eliminando el uso de comandas en papel y reduciendo errores en los pedidos.",
        date: "March 25, 2025",
        views: "2.3k",
        comments: "27",
        href: "/blog/kds-incorporado-con-pos-la-clave-para-una-cocina-eficiente",
        imageSrc:
          "https://img.invupos.com/webinvu/Blog%20invu/kds-incorporado-con-pos-la-clave-para-una-cocina-eficiente.jpeg",
        imageAlt: "",
        category: "Management",
        typecontent: "Articles",
        country: "Guatemala", 
      },
  ];

  const sortedData = data.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
  const sortedFilteredData = filteredData
  ? filteredData.slice().sort((a, b) => new Date(b.date) - new Date(a.date))
  : null;

  const filterItems = () => {
    let filteredData;
  
    if (
      selectedCategory === "All" &&
      selectedTypeContent === "All" &&
      selectedCountry === "All"
    ) {
      // If all filters are "All", show all blogs
      filteredData = data;
    } else {
      // Apply filtering logic based on selected values
      filteredData = data.filter((item) => {
        const categoryCondition = selectedCategory === "All" || item.category === selectedCategory;
        const typeContentCondition = selectedTypeContent === "All" || item.typecontent === selectedTypeContent;
        const countryCondition =
          selectedCountry === "All" || (Array.isArray(item.countries) ? item.countries.includes(selectedCountry) : item.country === selectedCountry);
  
        return categoryCondition && typeContentCondition && countryCondition;
      });
    }
  
    setFilteredData(filteredData);
  
    if (filteredData.length === 0) {
      setErrorMessage(
        `No items found for ${selectedTypeContent} ${selectedCategory} ${selectedCountry}`
      );
    } else {
      setErrorMessage("");
    }
  };

  const resetFilter = () => {
    setSelectedCategory("All");
    setSelectedTypeContent("All");
    setSelectedCountry("All");
    setFilteredData(data);
    setErrorMessage("");
  };

  const uniqueCategories = [
    "All",
    ...new Set(data.map((item) => item.category)),
  ];
  const uniqueTypeContent = [
    "All",
    ...new Set(data.map((item) => item.typecontent)),
  ];

  const location = useLocation();
  const prefix = useLocalePrefix();
  const { t } = useTranslation();
  return (
    <>
      {isLoading && (
        <div class="fixed inset-0 flex items-center justify-center"> 
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      <section class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <div class="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
          <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
            {t("ourbloguppercase")}
          </h2>
          <h1 class="mb-6 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-5xl md:tracking-tight">
            {t("ourblogtitle1")}{" "}
            <span class="block w-full text-transparent bg-clip-text bg-gradient-to-r from-[#FFCD00] via-[#003087] to-[#C8102E] lg:inline">
              {t("ourblogtitlespan")}
            </span>{" "}
            {t("ourblogtitle2")}
          </h1>
        </div>

        <div
          class="
                  p-2
                  mt-16
                  transition
                  duration-500
                  ease-in-out
                  transform
                  border2
                  bg-gray-50
                  md:mx-auto
                  rounded-xl
                  sm:max-w-5xl
                  lg:flex
                  mb-8
                "
        >
          <div class="lg:flex space-x-12">
            <div class="flex-1 min-w-0 revue-form-group">
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                class="
                block
                w-full
                px-5
                py-3
                text-base text-neutral-600
                placeholder-gray-300
                transition
                duration-500
                ease-in-out
                transform
                bg-transparent
                border border-transparent
                rounded-md
                focus:outline-none
                focus:border-transparent
                focus:ring-2
                focus:ring-white
                focus:ring-offset-2
                focus:ring-offset-gray-300
              "
              >
                {/* {uniqueCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))} */}
                <option value="All">{t("categoryblog1")}</option>
                {/* <option value="Finances">{t("categoryblog2")}</option> */}
                {/* <option value="Payments">{t("categoryblog3")}</option> */}
                {/* <option value="Customers">{t("categoryblog4")}</option> */}
                <option value="Management">{t("categoryblog5")}</option>
                {/* <option value="Marketing">{t("categoryblog6")}</option> */}
                {/* <option value="Press release">{t("categoryblog7")}</option> */}
                {/* <option value="Informative">{t("categoryblog8")}</option> */}
                {/* <option value="System POS">Sistema POS</option> */}
                {/* <option value="Delivery">Delivery</option> */}
                {/* <option value="Educational">{t("categoryblog9")}</option> */}
              </select>
            </div>
            <div class="flex-1 min-w-0 revue-form-group">
              <select
                value={selectedTypeContent}
                onChange={(e) => setSelectedTypeContent(e.target.value)}
                class="
                block
                w-full
                px-5
                py-3
                text-base text-neutral-600
                placeholder-gray-300
                transition
                duration-500
                ease-in-out
                transform
                bg-transparent
                border border-transparent
                rounded-md
                focus:outline-none
                focus:border-transparent
                focus:ring-2
                focus:ring-white
                focus:ring-offset-2
                focus:ring-offset-gray-300
              "
              >
                {/* {uniqueTypeContent.map((typecontent) => (
                  <option key={typecontent} value={typecontent}>
                    {typecontent}
                  </option>
                ))} */}
                <option value="All">{t("typecontentblog1")}</option>
                <option value="Articles">{t("typecontentblog2")}</option>
                <option value="Guides">{t("typecontentblog3")}</option>
                {/* <option value="Tools">{t("typecontentblog4")}</option> */}
              </select>
            </div>
            <div class="flex-1 min-w-0 revue-form-group">
              <select
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
                class="
                block
                w-full
                px-5
                py-3
                text-base text-neutral-600
                placeholder-gray-300
                transition
                duration-500
                ease-in-out
                transform
                bg-transparent
                border border-transparent
                rounded-md
                focus:outline-none
                focus:border-transparent
                focus:ring-2
                focus:ring-white
                focus:ring-offset-2
                focus:ring-offset-gray-300"
              >
                <option value="All">Todos los países</option>
                {/* <option value="Panama">Panamá</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Mexico">México</option> */}
                {/* <option value="Colombia">Colombia</option> */}
                <option value="Guatemala">Guatemala</option>
              </select>
            </div>

          </div>
          <div class="mt-4 sm:mt-0 lg:ml-3 revue-form-actions">
            <button
              onClick={() => {
                filterItems();
                handleFilterButtonClick();
              }}
              class="
               block
               w-full
               px-5
               py-3
               text-base
               text-white
               bg-indigo-600
               border border-transparent
               rounded-lg
               shadow
               font-semibold
               hover:bg-indigo-700
               focus:outline-none
               focus:ring-2
               focus:ring-white
               focus:ring-offset-2
               focus:ring-offset-gray-300
               sm:px-10
             "
            >
              {t("filtersearchblogbutton")}
            </button>
          </div>
        </div>

        {/* <div class="grid grid-cols-1 mt-24 gap-12 md:grid-cols-2 lg:grid-cols-2 ">
          <div>
            <Link
              onClick={() => window.scrollTo(0, 0)}
              class="block"
              to={`${prefix}` + "/blog/programas-de-contabilidad-en-la-nube"}
            >
              <img
                class="object-cover w-full h-82 shadow-xl rounded-xl"
                src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2021/Cover%20blog%2021%20.jpeg"
                alt=""
              />

              <div class="mt-10">
                <div class="h-full flex items-start">
                  <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
                    <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">
                      Jul
                    </span>
                    <span class="font-medium text-lg text-gray-800 title-font leading-none">
                      07
                    </span>
                  </div>
                  <div class="flex-grow pl-6">
                    <h2 class="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1 uppercase">
                      Destacado
                    </h2>
                    <h1 class="title-font max-w-md text-xl font-semibold text-gray-900 mb-3">
                      Software de contabilidad y facturación en la nube
                    </h1>
                    <p class="mt-2 max-w-lg text-gray-600 ">
                      A día de hoy, y gracias al avance de la tecnología, los
                      softwares de contabilidad en la nube se han convertido en
                      muy valiosas herramientas de trabajo para los
                      departamentos de contabilidad de las empresas.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <Link
              class="block"
              onClick={() => window.scrollTo(0, 0)}
              to={`${prefix}` + "/blog/facturacion-electronica-dgi-2022"}
            >
              <img
                class="object-cover w-full h-82 shadow-xl rounded-xl"
                src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2022/Cover%20blog%2022%20.jpeg"
                alt=""
              />

              <div class="mt-10">
                <div class="h-full flex items-start">
                  <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
                    <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">
                      Jul
                    </span>
                    <span class="font-medium text-lg text-gray-800 title-font leading-none">
                      08
                    </span>
                  </div>
                  <div class="flex-grow pl-6">
                    <h2 class="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1">
                      DESTACADO
                    </h2>
                    <h1 class="title-font max-w-md text-xl font-semibold text-gray-900 mb-3">
                      Facturación electrónica en Panamá: requisitos y novedades
                    </h1>
                    <p class="mt-2 max-w-lg text-gray-600 ">
                      En Panamá, la factura electrónica es un modelo de
                      facturación que, además de reemplazar el uso de impresoras
                      fiscales, acelera la transformación digital de las
                      empresas e impulsa el ingreso de los emprendedores en la
                      economía formal.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div> */}
      </section>
      {showButton && (
        <>
          <button
            onClick={handleScrollToTop}
            class="fixed bottom-0 right-0 m-4 p-2 group flex items-center justify-between gap-4 rounded-lg border border-indigo-600 bg-indigo-600 px-5 py-3 transition-colors hover:bg-indigo-700 focus:outline-none focus:ring"
          >
            <span class="font-medium text-white transition-colors group-hover:text-white group-active:text-indigo-500">
              {t("fixedbuttonscroll")}
            </span>

            <span class="flex-shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </button>
        </>
      )}
      <section id="results-section" className="bg-gray-100">
        <div className="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <h1 className="-mt-8 text-4xl font-bold xl:block hidden leading-tight text-gray-800">
            {t("titlelistblog")}
          </h1>
          <span class="block w-24 h-2 mt-1 xl:block hidden  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>

          <h1 className="-mt-8 text-3xl mb-4 font-bold xl:hidden block leading-tight lg:leading-10 text-gray-800">
            {t("titlelistblog")}
          </h1>
          <span class="block w-24 h-2 mt-1 lg:invisible  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>
          <div>
            {" "}
            {errorMessage && (
              <>
                <div class="rounded-3xl mt-8 shadow-2xl">
                  <div class="p-8 text-center sm:p-12">
                    <p class="text-sm font-semibold uppercase tracking-widest text-indigo-500">
                      {t("errormessageblogs")}
                    </p>

                    <h2 class="mt-6 text-3xl font-bold">{errorMessage}</h2>

                    <button
                      class="mt-8 inline-block lg:w-96 md:w-96 w-52 rounded-full bg-indigo-600 py-4 text-lg font-bold text-white transition hover:scale-110 hover:shadow-xl focus:outline-none focus:ring active:bg-indigo-500"
                      onClick={resetFilter}
                    >
                      {t("buttonrefreshblogs")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="grid gap-6  grid-cols-1 mt-8 grid grid-cols-1 gap-12 md:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3">
            {!filteredData
              ? sortedData.map((item, index) => (
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    to={`${prefix}` + item.href}
                    key={index}
                  >
                    <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                      <a aria-label="Article">
                        <img
                          src={item.imageSrc}
                          className="object-cover w-full h-64 rounded-t-lg"
                          alt=""
                        />
                      </a>
                      <div className="py-5 p-6">
                        <span class="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-700">
                          {item.typecontent}
                        </span>
                        <div className="flex justify-between items-end mt-3">
                          {item.countries && Array.isArray(item.countries) ? (
                            <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                              {item.date} | {item.countries.join(', ')}
                            </p>
                          ) : (
                            <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                              {item.date} | {item.country}
                            </p>
                          )}
                        </div>
                        <a
                          aria-label="Article"
                          className="inline-block mt-2.5 mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
                        >
                          <p className="text-2xl font-bold leading-7">
                            {item.name.length > 72
                              ? `${item.name.substring(0, 72)}...`
                              : item.name}
                          </p>
                        </a>
                        <p className="mb-4 text-gray-700">
                          {item.description.length > 240
                            ? `${item.description.substring(0, 238)}...`
                            : item.description}
                        </p>
                        <div class="mt-1.5 sm:mt-0">
                          <p class="text-gray-500 italic text-sm">{item.category}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
                : sortedFilteredData.map((item, index) => (
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    to={`${prefix}` + item.href}
                    key={index}
                  >
                    <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                      <a aria-label="Article">
                        <img
                          src={item.imageSrc}
                          className="object-cover w-full h-64 rounded-t-lg"
                          alt=""
                        />
                      </a>
                      <div className="py-5 p-6">
                      <span class="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-700">
                          {item.typecontent}
                        </span>
                        <div className="flex justify-between items-end mt-3">
                          {item.countries && Array.isArray(item.countries) ? (
                            <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                              {item.date} | {item.countries.join(', ')}
                            </p>
                          ) : (
                            <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                              {item.date} | {item.country}
                            </p>
                          )}
                        </div>
                        <a
                          aria-label="Article"
                          className="inline-block mt-2.5 mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
                        >
                          <p className="text-2xl font-bold leading-7">
                            {item.name.length > 72
                              ? `${item.name.substring(0, 72)}...`
                              : item.name}
                          </p>
                        </a>
                        <p className="mb-4 text-gray-700">
                          {item.description.length > 240
                            ? `${item.description.substring(0, 238)}...`
                            : item.description}
                        </p>
                        <div class="mt-1.5 sm:mt-0">
                          <p class="text-gray-500 italic text-sm">{item.category}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogGT;
