import React, { useState } from 'react'
import Modal_contact_pipedrive from "./Modal_contact_pipedrive";


const Test_button_pd = () => {
    const [isOpen, setIsOpen] = useState(false);
    
  return (
    <>
    {isOpen && <Modal_contact_pipedrive setIsOpen={setIsOpen} />}
    <section className='mx-auto align-center flex justify-center'>
         <button
            onClick={() => {
            setIsOpen(true);
        }}
            class="relative inline-flex items-center bg-indigo-700 hover:opacity-90 px-8 py-3.5 overflow-hidden text-white rounded-full group"

        >
            <span class="font-medium">Modal Pipedrive</span>
        </button>
    </section>
    </>
  )
}

export default Test_button_pd