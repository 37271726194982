import React, { useState } from 'react'

const Testblog = () => {

    const [isOpen, setIsOpen] = useState(false);

  return (
    <section class="w-full px-4 py-24 mx-auto max-w-7xl md:w-4/5">
    <div class="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
      <div class="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-3">
        <h1 class="mb-10 text-5xl font-medium text-gray-900 md:text-6xl max-w-4xl" itemprop="headline">
            5 Lessons I Learned Launching My Cult-Favorite Skincare Line in a Crowded Retail Market
        </h1>
      </div>
    </div>
    <div className="max-w-screen-xl mx-auto mt-4">
        <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-16 gap-x-10 md:gap-x-16">
          <div class="order-none lg:order-2 md:order-none">
          <p className="text-xl text-gray-900 font-light leading-relaxed mb-12">
          Trying to succeed in a crowded product space is tough. Discover 5 things Sofie Pavitt learned building her own product line.
          Trying to succeed in a crowded product space is tough. Discover 5 things Sofie Pavitt learned building her own product line.
          Trying to succeed in a crowded product space is tough. Discover 5 things Sofie Pavitt learned building her own product line.
          
          </p>
        <div className="flex items-center space-x-2 mt-4 text-gray-500 uppercase text-xs font-semibold">
          <span>By <span className="text-black">Andrés Amaya</span></span>
          <span>&#8212;</span>
          <span>MAR 13, 2025</span>
          <span>&#8212;</span>
          <span className="text-black">4 MIN READ</span>
        </div>
        <div className="flex space-x-2 mt-4">
          <span className="bg-blue-100 text-black text-xs font-semibold px-3 py-1 rounded">SELLING ANYWHERE</span>
          <span className="bg-gray-200 text-black text-xs font-semibold px-3 py-1 rounded">BEAUTY AND PERSONAL CARE</span>
        </div>
        {/* Social Icons */}
        <div className="flex space-x-4 mt-6">
          <a href="#" className="w-8 h-8 flex items-center justify-center border rounded-full text-gray-700 hover:bg-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24"><path d="M22 12a10 10 0 1 0-11 9.95V15h-2v-3h2V9.5c0-2 1.5-3.5 3.5-3.5H17v3h-1.5c-.5 0-1 .5-1 1V12h3l-1 3h-2v6.95A10 10 0 0 0 22 12z"/></svg>
          </a>
          <a href="#" className="w-8 h-8 flex items-center justify-center border rounded-full text-gray-700 hover:bg-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24"><path d="M24 4.5c-.9.4-1.8.7-2.8.8 1-.6 1.8-1.5 2.1-2.7-.9.5-2 .9-3.1 1.2C18.3 2.5 17 2 15.6 2c-2.7 0-5 2.2-5 5 0 .4 0 .8.1 1.2-4-.2-7.6-2.1-10-5-.4.7-.6 1.5-.6 2.4 0 1.7.9 3.2 2.2 4-1 0-1.5-.2-2.2-.6v.1c0 2.3 1.7 4.3 3.9 4.8-.4.1-.8.2-1.3.2-.3 0-.6 0-.9-.1.6 1.9 2.4 3.4 4.6 3.4-1.7 1.3-3.8 2.1-6.1 2.1-.4 0-.8 0-1.2-.1 2.2 1.4 4.8 2.2 7.5 2.2 9 0 14-7.5 14-14v-.6c1-.7 1.8-1.5 2.4-2.5z"/></svg>
          </a>
          <a href="#" className="w-8 h-8 flex items-center justify-center border rounded-full text-gray-700 hover:bg-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24"><path d="M10.59 13.41a2 2 0 0 1 0-2.82l5.17-5.17a2 2 0 0 1 2.82 2.82L13.41 13.41a2 2 0 0 1-2.82 0zm-1.18 1.18a4 4 0 0 0 5.66 0l5.17-5.17a4 4 0 1 0-5.66-5.66L8.41 8.93a4 4 0 0 0 0 5.66zM4 20a1 1 0 0 1 0-2h16a1 1 0 0 1 0 2H4z"/></svg>
          </a>
        </div>
            </div>
            <div className="relative lg:m-auto group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-2xl"
                src="https://images.pexels.com/photos/7433853/pexels-photo-7433853.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
          </div>
        </div>

        <hr class="my-16 h-0.5 border-t-0 bg-neutral-100" />

        <div className="bg-white flex flex-col md:flex-row justify-center items-start">
      {/* Sidebar - Table of Contents */}
      <div className="w-full md:w-1/3 md:pr-10 mb-10 md:mb-0">
        <div className="md:hidden">
          <button
            className="w-full flex items-center justify-between text-md font-semibold p-3 py-4 border-t border-b"
            onClick={() => setIsOpen(!isOpen)}
          >
             <span className="flex">Table of contents</span>
             <span className="ml-auto transition-transform duration-300 transform" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>
              {isOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M11.293 7.293a1 1 0 0 1 1.32 -.083l.094 .083l6 6l.083 .094l.054 .077l.054 .096l.017 .036l.027 .067l.032 .108l.01 .053l.01 .06l.004 .057l.002 .059l-.002 .059l-.005 .058l-.009 .06l-.01 .052l-.032 .108l-.027 .067l-.07 .132l-.065 .09l-.073 .081l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002h-12c-.852 0 -1.297 -.986 -.783 -1.623l.076 -.084l6 -6z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" />
                </svg>
              )}
            </span>
          </button>
          {isOpen && (
            <ul className="text-gray-700 space-y-2 text-md bg-gray-100 p-4 mt-6 rounded">
              <li>
                <a href="#section1" className="hover:text-indigo-500 transition-colors duration-300">
                  1. Know your customers’ problems deeply, and make it your mission to solve them
                </a>
              </li>
              <li>
                <a href="#section2" className="hover:text-v-500 transition-colors duration-300">
                  2. Stake out your territory
                </a>
              </li>
              <li>
                <a href="#section3" className="hover:text-indigo-500 transition-colors duration-300">
                  3. Focus on the ways you stand out from competitors
                </a>
              </li>
              <li>
                <a href="#section4" className="hover:text-indigo-500 transition-colors duration-300">
                  4. Be ready for surprises
                </a>
              </li>
              <li>
                <a href="#section5" className="hover:text-indigo-500 transition-colors duration-300">
                  5. Take a little bite every day
                </a>
              </li>
            </ul>
          )}
        </div>
        <div className="hidden md:block">
          <h2 className="text-xl font-semibold mb-4">Table of contents</h2>
          <ul className="text-gray-700 space-y-2 text-lg">
            <li>
              <a href="#" className="hover:text-indigo-500 transition-colors duration-300">
                1. Know your customers’ problems deeply, and make it your mission to solve them
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-indigo-500 transition-colors duration-300">
                2. Stake out your territory
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-indigo-500 transition-colors duration-300">
                3. Focus on the ways you stand out from competitors
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-indigo-500 transition-colors duration-300">
                4. Be ready for surprises
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-indigo-500 transition-colors duration-300">
                5. Take a little bite every day
              </a>
            </li>
          </ul>
        </div>
      </div>
      
      {/* Main Content */}
      <div className="w-full md:w-2/3 text-left">
        <p className="text-sm italic text-gray-500 mb-4">
          Sofie Pavitt was compensated for her time and participation by Square
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          When I started out as a solo esthetician renting a room in a small studio, I didn’t know that one day I would have my own product line. My clients achieved incredible before-and-after results with the products I recommended, but at the end of their treatment, they’d always ask me, “When can I start using nice products again?” My clients wanted skin products that were not only effective but also elegant. They wanted to get their acne care not in the drugstore but in places where they bought their other beauty products, like Sephora. They wanted the packaging to look pretty in their bathroom cabinets. They wanted what I couldn’t give them.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          That’s when I started thinking about what an elegantly formulated line would look like. And in 2023, I launched <a href="#" className="text-blue-500 underline">Sofie Pavitt Face</a>, which is now available through, yes, Sephora, among other places.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed">
          So, you could say my product line was born in the studio. Now, anyone can take a little slice of my studio home with you, no matter where you live.
        </p>

        <h2 id="section1" className="text-4xl font-bold mb-4 mt-12">
          1. Know your customers’ problems deeply, and make it your mission to solve them
        </h2>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          You can’t succeed in skincare without <a href="#" className="text-blue-500 underline">understanding your customers</a>. And as I’ve suggested, much of the market research for our products happened organically through my studio.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Before using any product in the studio, I would research the ingredients and study any available clinicals. Then I’d test the product on myself and on trusted friends and clients. Slowly I’d expand use among our clientele to gauge results and customer reactions.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Understanding your customers means learning their skincare needs and struggles. It means understanding the areas where they wish they had more and better options. It also means having detailed customer information during treatments so you can track issues over time. And it means finding out how much they spend on beauty products each month, where they like to shop, and what separates must-haves from splurges.
        </p>
      </div>

    </div>


    <div className="bg-white flex flex-col md:flex-row justify-center items-start mt-14">
      {/* Sidebar - Table of Contents */}
      <div className="w-full md:w-1/3 md:pr-10 mb-10 md:mb-0">
      <div className="bg-white flex flex-col items-start text-left p-6 max-w-md mx-auto">
      {/* Heading */}
      <h2 className="text-3xl font-medium leading-tight mb-4">
        Build your business with tools that move you forward
      </h2>
      
      {/* Image */}
      <div className="w-full rounded-lg overflow-hidden mb-6">
        <img
          src="https://images-cdn4.welcomesoftware.com/Zz02NDRiNTNlZTNmYjcxMWVkOTczOGNlYzE3NTliYmMzMw==?width=544&height=544"
          alt="Business Tools"
          className="w-full h-auto object-cover"
        />
      </div>
      
      {/* Button */}
      <a
        href="#"
        className="px-6 py-3 border border-blue-500 text-blue-500 font-medium rounded-lg hover:bg-blue-100 transition duration-300"
      >
        Get the tools ↗
      </a>
    </div>
      </div>
      
      {/* Main Content */}
      <div className="w-full md:w-2/3 text-left">
        <h2 id="section1" className="text-4xl font-bold mb-4 mt-12">
          2. Know your customers’ problems deeply, and make it your mission to solve them
        </h2>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          You can’t succeed in skincare without <a href="#" className="text-blue-500 underline">understanding your customers</a>. And as I’ve suggested, much of the market research for our products happened organically through my studio.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Before using any product in the studio, I would research the ingredients and study any available clinicals. Then I’d test the product on myself and on trusted friends and clients. Slowly I’d expand use among our clientele to gauge results and customer reactions.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Understanding your customers means learning their skincare needs and struggles. It means understanding the areas where they wish they had more and better options. It also means having detailed customer information during treatments so you can track issues over time. And it means finding out how much they spend on beauty products each month, where they like to shop, and what separates must-haves from splurges.
        </p>

        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Understanding your customers means learning their skincare needs and struggles. It means understanding the areas where they wish they had more and better options. It also means having detailed customer information during treatments so you can track issues over time. And it means finding out how much they spend on beauty products each month, where they like to shop, and what separates must-haves from splurges.
        </p>



        <h2 id="section1" className="text-4xl font-bold mb-4 mt-12">
          3. Know your customers’ problems deeply, and make it your mission to solve them
        </h2>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          You can’t succeed in skincare without <a href="#" className="text-blue-500 underline">understanding your customers</a>. And as I’ve suggested, much of the market research for our products happened organically through my studio.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Before using any product in the studio, I would research the ingredients and study any available clinicals. Then I’d test the product on myself and on trusted friends and clients. Slowly I’d expand use among our clientele to gauge results and customer reactions.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Understanding your customers means learning their skincare needs and struggles. It means understanding the areas where they wish they had more and better options. It also means having detailed customer information during treatments so you can track issues over time. And it means finding out how much they spend on beauty products each month, where they like to shop, and what separates must-haves from splurges.
        </p>

        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Understanding your customers means learning their skincare needs and struggles. It means understanding the areas where they wish they had more and better options. It also means having detailed customer information during treatments so you can track issues over time. And it means finding out how much they spend on beauty products each month, where they like to shop, and what separates must-haves from splurges.
        </p>
      </div>

    </div>


    <div className="bg-white flex flex-col md:flex-row justify-center items-start mt-14">
      {/* Sidebar - Table of Contents */}
      <div className="w-full md:w-1/3 md:pr-10 mb-10 md:mb-0">
      <div className="bg-white flex flex-col items-start text-left p-6 max-w-md mx-auto">
      {/* Heading */}
      <h2 className="text-3xl font-medium leading-tight mb-4">
        Build your business with tools that move you forward
      </h2>
      
      {/* Image */}
      <div className="w-full rounded-lg overflow-hidden mb-6">
        <img
          src="https://images-cdn4.welcomesoftware.com/Zz02NDRiNTNlZTNmYjcxMWVkOTczOGNlYzE3NTliYmMzMw==?width=544&height=544"
          alt="Business Tools"
          className="w-full h-auto object-cover"
        />
      </div>
      
      {/* Button */}
      <a
        href="#"
        className="px-6 py-3 border border-blue-500 text-blue-500 font-medium rounded-lg hover:bg-blue-100 transition duration-300"
      >
        Get the tools ↗
      </a>
    </div>
      </div>
      
      {/* Main Content */}
      <div className="w-full md:w-2/3 text-left">
        <h2 id="section1" className="text-4xl font-bold mb-4 mt-12">
          4. Know your customers’ problems deeply, and make it your mission to solve them
        </h2>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          You can’t succeed in skincare without <a href="#" className="text-blue-500 underline">understanding your customers</a>. And as I’ve suggested, much of the market research for our products happened organically through my studio.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Before using any product in the studio, I would research the ingredients and study any available clinicals. Then I’d test the product on myself and on trusted friends and clients. Slowly I’d expand use among our clientele to gauge results and customer reactions.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Understanding your customers means learning their skincare needs and struggles. It means understanding the areas where they wish they had more and better options. It also means having detailed customer information during treatments so you can track issues over time. And it means finding out how much they spend on beauty products each month, where they like to shop, and what separates must-haves from splurges.
        </p>

        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Understanding your customers means learning their skincare needs and struggles. It means understanding the areas where they wish they had more and better options. It also means having detailed customer information during treatments so you can track issues over time. And it means finding out how much they spend on beauty products each month, where they like to shop, and what separates must-haves from splurges.
        </p>



        <h2 id="section1" className="text-4xl font-bold mb-4 mt-12">
          5. Know your customers’ problems deeply, and make it your mission to solve them
        </h2>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          You can’t succeed in skincare without <a href="#" className="text-blue-500 underline">understanding your customers</a>. And as I’ve suggested, much of the market research for our products happened organically through my studio.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Before using any product in the studio, I would research the ingredients and study any available clinicals. Then I’d test the product on myself and on trusted friends and clients. Slowly I’d expand use among our clientele to gauge results and customer reactions.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Understanding your customers means learning their skincare needs and struggles. It means understanding the areas where they wish they had more and better options. It also means having detailed customer information during treatments so you can track issues over time. And it means finding out how much they spend on beauty products each month, where they like to shop, and what separates must-haves from splurges.
        </p>

        <p className="text-lg text-gray-900 leading-relaxed mb-6">
          Understanding your customers means learning their skincare needs and struggles. It means understanding the areas where they wish they had more and better options. It also means having detailed customer information during treatments so you can track issues over time. And it means finding out how much they spend on beauty products each month, where they like to shop, and what separates must-haves from splurges.
        </p>

        <div className="bg-white flex flex-col md:flex-row items-start max-w-3xl mx-auto border-t">
      {/* Image */}
      <div className="w-16 h-16 md:w-20 md:h-20 rounded-full overflow-hidden mr-4 mt-8">
        <img
          src="https://t4.ftcdn.net/jpg/03/83/25/83/360_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg"
          alt="Profile Picture"
          className="w-full h-full object-cover"
        />
      </div>
      
      {/* Text Content */}
      <div className="flex-1 text-left mt-8">
        <p className="text-lg text-gray-900 leading-relaxed">
          Known as the “NYC Acne Whisperer”, <strong>Sofie Pavitt</strong> is a pioneering skincare expert and licensed esthetician rethinking the way we care for problematic skin. After 15 years in the fashion industry, Pavitt found herself drawn to skincare, studying the detailed Korean beauty aisles in her downtime during regular work trips to Seoul. She’s since become the go-to facialist for high-profile models, editors, and celebrities, including Zendaya and Lorde, specializing in concerns like cystic acne and lackluster skin.
        </p>
        <p className="text-lg text-gray-900 leading-relaxed mt-4">
          Sofie is dedicated to treating clients between her two namesake skincare studios, on Grand Street downtown NYC and in Williamsburg, Brooklyn, as well as at Center Aesthetic and Dermatology in Manhattan where she’s able to provide advanced facial services using the latest in micro-needling and laser technology. In March 2023, the Sofie Pavitt Face skincare line was born with the ethos that fewer, better products, and proper education are the key to your best skin yet.
        </p>
      </div>
    </div>
      </div>

    </div>

  </section>

  )
}

export default Testblog