import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useLocalePrefix from "../../utils/useLocalePrefix";
import Modal_started from "../../Home/Modal_started";

const ModalContent = ({ setIsOpenModal, iframeSrc, title }) => {
  const { t } = useTranslation();
  const [height, setHeight] = useState('100vh');

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setHeight('45vh');
      } else if (width <= 640) {
        setHeight('50vh');
      } else if (width <= 768) {
        setHeight('60vh');
      } else if (width <= 1024) {
        setHeight('80vh');
      } else {
        setHeight('100vh');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative mt-12 bg-black py-8">
        {/* Botón de cierre más visible y dentro de un círculo */}
        <button 
        onClick={() => setIsOpenModal(false)} 
        className="absolute top-10 right-8 flex items-center justify-center w-12 h-12 bg-gray-800 rounded-full focus:outline-none"
        >
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="w-8 h-8 text-white" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
            strokeWidth="2"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </button>
        <h2 className="text-xl font-semibold text-center text-white relative top-10">{title}</h2>
        <iframe
          style={{
            border: 'none',
            width: '100vw',
            height: height,
            margin: 0,
            padding: 0,
            outline: 'none' 
          }}
          src={iframeSrc}
          allowFullScreen
        />
      </div>
    </div>
  );
};

const Clientes = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  const handleOpenModal = (src, title) => {
    setIframeSrc(src);
    setModalTitle(title);
    setIsOpenModal(true);
  };

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      {isOpenModal && <ModalContent setIsOpenModal={setIsOpenModal} iframeSrc={iframeSrc} title={modalTitle} />}
      <div className="mt-4 max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 bg-white rounded-3xl">
        <div className="grid gap-8 row-gap-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-1">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal('https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0fSE6NkIcJfWM7lrtwT6tD%2FDemosnew%3Fnode-id%3D12-8099%26p%3Df%26scaling%3Dscale-down%26content-scaling%3Dfixed%26page-id%3D12%253A5316&hide-ui=1', 'Cómo registrar un cliente');
            }}
            aria-label="View Item"
            className="inline-block overflow-hidden duration-300 transform bg-white rounded-2xl shadow-sm hover:-translate-y-2"
          >
            <div className="flex flex-col h-full">
              <img
                src="https://img.invupos.com/webinvu/Demos%20Invu/Cover%20clientes%201.png"
                className="object-cover w-full h-50"
                alt=""
              />
              <div className="flex-grow border border-t-0 rounded-2xl">
                <div className="p-5">
                  <h2 className="mb-2 font-semibold leading-5 text-lg">
                    {t("supportsection3card2title")}
                  </h2>
                  <p className="text-md text-gray-900">
                    {t("supportsection3card2description")}
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Clientes;
