import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
// Modal Contact

import Modal_started from "../../components/Home/Modal_started";
import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../../components/utils/useLocalePrefix";

const HeroRD = () => {

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");
  const [isOpen, setIsOpen] = useState(false);

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 20000,
    slidesToShow: 6,
    slidesToScroll: 4,
    initialSlide: 0,
    cssEase: "linear",
    className: "wrapper",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,

          // dots: true,
        },
      },
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
       {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section className="bg-gray-50 lg:pt-24">
        <div>
          <div className="px-4 relative mx-auto sm:max-w-xl md:max-w-full lg:px-8">
            <div className="px-4  text-left  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 content">
              <div className="grid gap-12 row-gap-8 lg:grid-cols-2 lg:mt-8 mt-0">
                <div className="flex flex-col justify-center">
                  <div className="max-w-xl mb-6 mt-10 lg:-mt-12 md:mt-24">
                    <h1 class="font-bold xl:text-[68px] lg:text-6xl md:text-6xl text-5xl max-w-lg text-[#002D33]">
                    ¡{t("herotittle")}{" "}
                    <span className="bg-gradient-cliprd">{t("herotittlespan1")}</span>!
                    </h1>
                    <p class="mt-5 text-gray-700 leading-loose max-w-2xl font-light">
                    {t("herodescription1")} <br/> <span className="font-semibold">{t("herodescription2")}</span>
                    </p>
                  </div>
                  <div className="flex gap-x-4">
                    <button
                     onClick={() => {
                      setIsOpen(true);
                    }}
                      class="relative inline-flex items-center bg-[#CE1126] hover:opacity-90 px-8 py-3.5 overflow-hidden text-white rounded-full group "

                    >
                      <span class="font-medium">{t("heroglobalbutton1")}</span>
                    </button>
                    {/* <Link
                      to={`${prefix}` + "/demo-prototipo"}
                      onClick={() => window.scrollTo(0, 0)}
                      class="relative inline-flex items-center bg-[#4997d0] hover:opacity-90 px-8 py-3.5 overflow-hidden text-white rounded-full group "
                    >
                      <span class="font-medium">{t("heroglobalbutton2")}</span>
                    </Link> */}
                  </div>
                  

                  <div class="mt-6 lg:mt-12 mb-6">
                    <span class="text-xs font-semibold text-gray-500 uppercase tracking-widest">{t("availableinhero")}</span>
                    <div class="mt-4 flex gap-x-8">
                      <a href="https://bit.ly/4cXCHQm" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <path fill="currentColor" d="m3.001 5.479l7.377-1.016v7.127H3zm0 13.042l7.377 1.017v-7.04H3zm8.188 1.125L21.001 21v-8.502h-9.812zm0-15.292v7.236h9.812V3z"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/3W8xWN4" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 315">
                            <path fill="currentColor" d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/4cJt3Be" target="_blank">
                          <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
                              <path fill="currentColor" fill-rule="evenodd" d="M12.735 1.828L13.6.254a.172.172 0 0 0-.3-.164l-.874 1.59a5.987 5.987 0 0 0-2.428-.508c-.869 0-1.69.181-2.424.506L6.698.09a.171.171 0 0 0-.232-.069a.169.169 0 0 0-.069.232l.866 1.572c-1.7.874-2.85 2.537-2.849 4.447l11.166-.001c0-1.91-1.146-3.57-2.845-4.444m-5.278 2.43a.467.467 0 1 1 .001-.934a.467.467 0 0 1 0 .935M15.542 6.7l.002 8.012c0 .481-.262.897-.648 1.127a1.31 1.31 0 0 1-.675.19l-.904.001v2.734a1.237 1.237 0 0 1-1.489 1.21a1.238 1.238 0 0 1-.99-1.209V16.03H9.163v2.735A1.237 1.237 0 0 1 7.925 20a1.239 1.239 0 0 1-1.238-1.235V16.03h-.901c-.521 0-.967-.3-1.182-.736a1.298 1.298 0 0 1-.141-.581l-.002-8.01zM2.74 6.47c.684 0 1.24.553 1.24 1.234v5.17a1.238 1.238 0 0 1-1.922 1.03a1.231 1.231 0 0 1-.557-1.03L1.5 7.703c0-.68.555-1.234 1.24-1.234m14.52-.001c.684 0 1.24.552 1.24 1.234v5.169c0 .683-.555 1.235-1.239 1.235c-.685 0-1.24-.552-1.24-1.235V7.702c0-.682.554-1.235 1.238-1.234M12.54 3.325a.467.467 0 1 1 0 .933a.467.467 0 1 1 0-.933"/>
                          </svg>
                      </a>
                    </div>
                  </div>

                </div>

                <Carousel
                  className="z-10 relative ss"
                  autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  interval={6000}
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeAnimationHandler={false}
                  animationHandler="fade"
                  handleTransition="fade"
                  transitionTime={500}
                >
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/webinvu/Invu%20RD/RDHEROIMG1.1.png"
                      alt="Ecommerce Hero"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/webinvu/Invu%20RD/RDHEROIMG2.1.webp"
                      alt="Ecommerce Hero"
                      loading="lazy"
                    />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>

        <div className="relative px-4 sm:px-0 ">
          <div className="absolute inset-0 bg-[#FFE6DE] h-1/2"/>
          <div className="relative grid mx-auto overflow-hidden  bg-white divide-y rounded-full shadow sm:divide-y-0 sm:divide-x sm:max-w-screen-sm  lg:max-w-screen-xl">

          </div>
        </div>
      </section>
    </>
  );
};

export default HeroRD;
