import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const Modal_contact_pipedrive = ({ setIsOpen }) => {

  const history = useHistory();
  const { t } = useTranslation();
  const prefix = useLocalePrefix();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    organization: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false); // Estado para el checkbox
  const [country, setCountry] = useState(""); // Estado para el país
  const [location, setLocation] = useState(''); // Para almacenar la Ubicación

  useEffect(() => {
    // Obtener la ubicación basada en IP
    const fetchLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/'); // Usar un servicio de geolocalización
        const data = await response.json();
        setLocation(data.country_name || ''); // Guardar el país
      } catch (error) {
        console.error('Error al obtener la ubicación:', error);
      }
    };

    fetchLocation();
  }, []);

   // Diccionario de países con nombres formateados
   const countryNames = {
    costarica: "Costa Rica",
    elsalvador: "El Salvador",
    mexico: "México",
    panama: "Panamá",
    venezuela: "Venezuela",
    colombia: "Colombia",
  };

  // Cargar y formatear el país desde localStorage cuando se monta el modal
  useEffect(() => {
    const storedCountry = localStorage.getItem("selectedCountry") || "No disponible";
    
    // Verificamos si el país existe en nuestro diccionario
    const formattedCountry = countryNames[storedCountry.toLowerCase()] || storedCountry;

    setCountry(formattedCountry);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que el checkbox esté marcado antes de enviar
    if (!isChecked) {
      setError("Debes aceptar la política de privacidad antes de continuar.");
      return;
    }
   
    if (!formData.name || !formData.email || !formData.phone || !formData.organization) {
      setError("Todos los campos son obligatorios.");
      return;
    }
    setLoading(true);
    setError("");

    const apiKey = "8bb3ead1828b0ca5512f504dbed1d0758bf5b583"; // Reemplázalo con tu API Key
    const baseUrl = `https://api.pipedrive.com/v1`;

    try {
      const organizationResponse = await fetch(`${baseUrl}/organizations?api_token=${apiKey}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: formData.organization }),
      });
      const organizationData = await organizationResponse.json();
      if (!organizationResponse.ok) throw new Error(`Error al crear la organización: ${organizationData.error}`);

      const contactResponse = await fetch(`${baseUrl}/persons?api_token=${apiKey}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          org_id: organizationData.data.id,
        }),
      });
      const contactData = await contactResponse.json();
      if (!contactResponse.ok) throw new Error(`Error al crear el contacto: ${contactData.error}`);

      const leadResponse = await fetch(`${baseUrl}/leads?api_token=${apiKey}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          title: formData.organization,
          person_id: contactData.data.id,
          organization_id: organizationData.data.id,
          channel_id: `Formulario Web Invu ${country}`, // Concatenando el country
          '2da607028218bf119ceafa01dc19635007d7e8fe': location, //Ubicación
        }),
      });
      const leadData = await leadResponse.json();
      if (!leadResponse.ok) throw new Error(`Hubo un error al enviar el formulario. ${leadData.error}`);

      setResponseMessage("Formulario enviado con éxito");
      history.push(`/test-contact-form-pipedrive?person_id=${contactData.data.id}&org_id=${organizationData.data.id}&lead_id=${leadData.data.id}`);
      setIsOpen(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center fixed inset-0 z-50 bg-gray-500 bg-opacity-75">
      <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl bg-white">
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between p-6 border-b border-gray-200">
            <p className="font-semibold text-gray-800">{t("modaltrydemo")}</p>
            <button type="button" onClick={() => setIsOpen(false)}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="px-6 py-5 bg-gray-50">
            <div className="mb-6">
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <img
                  className="h-16 w-auto cursor-pointer"
                  src="https://res.cloudinary.com/dslkvd23s/image/upload/v1648001932/Branding%20Invu%20/Logo%20Invu/logo-INVU-POS-rgb_nrg6ry.png"
                  alt=""
                />
              </Link>
            </div>
            <input className="w-full p-4 mb-3 border rounded" name="name" placeholder="Nombre Completo" onChange={handleChange} required />
            <input className="w-full p-4 mb-3 border rounded" name="email" type="email" placeholder="Correo Electrónico" onChange={handleChange} required />
            <input className="w-full p-4 mb-3 border rounded" name="phone" placeholder="Teléfono" onChange={handleChange} required />
            <input className="w-full p-4 mb-3 border rounded" name="organization" placeholder="Nombre del Negocio" onChange={handleChange} required />
             {/* Checkbox para aceptar la política de privacidad */}
             <div className="mt-3 flex items-start">
              <div className="flex">
                <input
                  type="checkbox"
                  required
                  className="shrink-0 mt-1.5 text-blue-600 border-gray-200 rounded focus:ring-blue-500"
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="ms-3 ml-2">
                <label className="text-sm text-gray-500">
                  {t("contactexternalformpolicy1")} {" "}
                  <Link to={"/politicas-de-privacidad"} className="text-indigo-600 decoration-2 underline font-medium">
                    {t("contactexternalformpolicyspan")}
                  </Link> {" "}
                  {t("contactexternalformpolicy2")}
                </label>
              </div>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </div>
          <div className="flex justify-between p-5 border-t border-gray-200 items-center">
            <p onClick={() => setIsOpen(false)} className="font-semibold text-gray-600 cursor-pointer">Cancelar</p>
            <button type="submit" className="px-8 py-3 bg-indigo-500 text-white rounded hover:opacity-90">
              {loading ? "Enviando..." : t("modalformbutton")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal_contact_pipedrive;

