import React from 'react'
import Test_button_pd from './Test_button_pd'

const Button_test_component = () => {
  return (
    <>
    <div className='h-[70vh] flex items-center'>
        <Test_button_pd/>
    </div>
    </>
  )
}

export default Button_test_component